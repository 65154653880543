import { BasicSelect, Body2, List, ListItem, Small2, Textarea, TextInput } from '@meterup/metric';
import { AriaTextFieldOptions, useNumberField, useTextField } from '@meterup/react-aria';
import { useNumberFieldState } from '@meterup/react-stately';
import { AriaNumberFieldProps } from '@react-types/numberfield';
import { HelpTextProps } from '@react-types/shared';
import React, { ComponentProps, HTMLAttributes, ReactNode, useRef } from 'react';

import { colors, fontWeights, styled } from '../../stitches';

const FieldContainer = styled('div', List);

const FieldHeading = styled('div', {
  hStack: '$12',
});

const FieldControls = styled('div', {
  hStack: '$8',
  marginLeft: 'auto',
});

const FieldLabel = styled('label', Body2, {
  fontWeight: fontWeights.medium,
  color: colors['gray-700'],
});

const FieldMain = styled(ListItem, {
  vStack: '$8',
  alignItems: 'stretch',
  display: 'flex',
});

const ErrorMessage = styled('div', ListItem, {
  position: 'relative',
  fontSize: '$14',
  lineHeight: '$20',
  color: colors['red-700'],
  backgroundColor: colors['red-50'],
  padding: '$4 $8 $4 $16',
  '&:before': {
    position: 'absolute',
    content: '" "',
    display: 'block',
    width: '$4',
    left: '$4',
    top: '$4',
    bottom: '$4',
    borderRadius: '$4',
    backgroundColor: colors['red-600'],
  },
});

interface BaseFieldProps {
  label: ReactNode;
  labelProps?: HTMLAttributes<HTMLElement>;
  controls?: ReactNode;
  input: ReactNode;
  description?: ReactNode;
  descriptionProps?: HTMLAttributes<HTMLElement>;
  errorMessage?: ReactNode;
  errorMessageProps?: HTMLAttributes<HTMLElement>;
}

const BaseField = ({
  label,
  labelProps,
  controls,
  input,
  description,
  descriptionProps,
  errorMessage,
  errorMessageProps,
}: BaseFieldProps) => (
  <FieldContainer>
    <FieldMain>
      <FieldHeading>
        <FieldLabel {...labelProps}>{label}</FieldLabel>
        {controls && <FieldControls>{controls}</FieldControls>}
      </FieldHeading>
      {input}
      {description && <Small2 {...descriptionProps}>{description}</Small2>}
    </FieldMain>
    {errorMessage && <ErrorMessage {...errorMessageProps}>{errorMessage}</ErrorMessage>}
  </FieldContainer>
);

interface CommonFieldProps {
  id?: string;
  name?: string;
  label: ReactNode;
  controls?: ReactNode;
}

interface TextFieldProps extends Omit<AriaTextFieldOptions<'input'>, 'label'>, CommonFieldProps {}

export const TextField = (props: TextFieldProps) => {
  const { label, controls, description, errorMessage } = props;
  const ref = useRef<HTMLInputElement>(null);
  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(props, ref);

  return (
    <BaseField
      label={label}
      labelProps={labelProps}
      controls={controls}
      input={<TextInput {...(inputProps as any)} size="large" ref={ref} />}
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
    />
  );
};

interface TextAreaFieldProps
  extends Omit<AriaTextFieldOptions<'textarea'>, 'label'>,
    CommonFieldProps {}

export const TextAreaField = (props: TextAreaFieldProps) => {
  const { label, controls, description, errorMessage } = props;
  const ref = useRef<HTMLTextAreaElement>(null);
  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(props, ref);

  return (
    <BaseField
      label={label}
      labelProps={labelProps}
      controls={controls}
      input={<Textarea {...(inputProps as any)} size="large" ref={ref} />}
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
    />
  );
};

interface NumberFieldProps extends Omit<AriaNumberFieldProps, 'label'>, CommonFieldProps {}

export const NumberField = (props: NumberFieldProps) => {
  const { label, controls, description, errorMessage } = props;
  const ref = useRef<HTMLInputElement>(null);
  const state = useNumberFieldState({ ...props, locale: 'en' });
  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useNumberField(
    props,
    state,
    ref,
  );

  return (
    <BaseField
      label={label}
      labelProps={labelProps}
      controls={controls}
      input={
        <TextInput
          {...(inputProps as any)}
          name={props.name}
          id={props.id}
          size="large"
          ref={ref}
        />
      }
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
    />
  );
};

interface SelectFieldProps
  extends ComponentProps<typeof BasicSelect>,
    CommonFieldProps,
    HelpTextProps {
  defaultValue: string;
  errorMessage?: string | false | undefined;
  description?: string | false | undefined;
}

/**
 * NOTE: This component is not implemented completely and does not integrate
 * with react-aria properly. It will be moved to the @meterup/metric package
 * where we can use the `useSelect` hook directly to wire up up the label,
 * description, and error text.
 */
export const BasicSelectField = ({
  label,
  controls,
  description,
  errorMessage,
  ...props
}: SelectFieldProps) => (
  <BaseField
    label={label}
    labelProps={{}}
    controls={controls}
    input={<BasicSelect {...(props as any)} size="large" aria-label={' '} />}
    description={description}
    descriptionProps={{}}
    errorMessage={errorMessage}
    errorMessageProps={{}}
  />
);
