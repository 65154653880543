import { Body, Button, HStack, NewSelect, space } from '@meterup/metric';
import { Item } from '@meterup/react-stately';
import { first, last } from 'lodash';
import React from 'react';
import { UsePaginationInstanceProps, UsePaginationState } from 'react-table';

import { styled } from '../../stitches';

const PaginationContent = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$4',
  whiteSpace: 'nowrap',
  justifyContent: 'space-between',
  width: '100%',
});

const PAGE_SIZES = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

export interface PaginationProps {
  instanceProps: UsePaginationInstanceProps<any> & { state: UsePaginationState<any> };
}

export const Pagination: React.FunctionComponent<PaginationProps> = ({ instanceProps }) => {
  const from = first(instanceProps.page)?.id ?? '0';
  const to = last(instanceProps.page)?.id ?? '0';
  return (
    <PaginationContent>
      <HStack spacing={space(8)} width="max-content">
        <Body>Show up to</Body>
        <NewSelect
          aria-label="Page size"
          size="small"
          selectedKey={instanceProps.state.pageSize.toString()}
          onSelectionChange={(v) => instanceProps.setPageSize(v as number)}
        >
          {PAGE_SIZES.map((size) => (
            <Item key={size.value}>{size.label}</Item>
          ))}
        </NewSelect>
        <Body>rows</Body>
      </HStack>

      <HStack spacing={space(8)}>
        <Button
          arrangement="hidden-label"
          disabled={!instanceProps.canPreviousPage}
          onClick={instanceProps.previousPage}
          size="small"
          type="button"
          variant="tertiary"
          icon="arrowLeft"
        >
          Previous
        </Button>
        <Body>
          {parseInt(from, 10) + 1} to {parseInt(to, 10) + 1}
        </Body>
        <Button
          arrangement="hidden-label"
          disabled={!instanceProps.canNextPage}
          onClick={instanceProps.nextPage}
          size="small"
          type="button"
          variant="tertiary"
          icon="arrowRight"
        >
          Next
        </Button>
      </HStack>
    </PaginationContent>
  );
};

export default Pagination;
