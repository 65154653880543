import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Route } from 'react-router-dom';

import { routes } from '../../routes';
import { styled } from '../../stitches';
import muiTheme from '../../theme';
import VPN from './VPN';
import VPNClient from './VPNClient';
import VPNClientCreate from './VPNClientCreate';

const Content = styled('div', {
  padding: '$20',
  minWidth: 'fit-content',
  minHeight: 'fit-content',
});

const VPNContainer = () => (
  <MaterialThemeProvider theme={muiTheme}>
    <Content>
      <Route path={routes.vpn.overview.path} component={VPN} exact />
      <Route path={routes.vpn.create.path} component={VPNClientCreate} exact />
      <Route path={routes.vpn.client.path} component={VPNClient} exact />
    </Content>
  </MaterialThemeProvider>
);

export default VPNContainer;
