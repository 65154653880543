import * as d3 from 'd3';
import React, { SVGAttributes } from 'react';

export const CircularProgressGauge = ({
  progress,
  total = 1,
  size = 16,
  strokeWidth = 1,
  ...props
}: {
  progress: number;
  total?: number;
  strokeWidth?: number;
  size?: number;
} & SVGAttributes<SVGSVGElement>) => {
  const r = size / 2 - strokeWidth;
  const t = progress / total;
  const arc = d3.arc();
  const scale = d3
    .scaleLinear()
    .domain([0, 1])
    .range([2 * Math.PI, 0]);

  const d = arc({
    innerRadius: r - strokeWidth / 2,
    outerRadius: r + strokeWidth - strokeWidth / 2,
    startAngle: scale(0),
    endAngle: scale(t),
  });

  return (
    <svg {...props} width={size} height={size}>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={r}
        fill="none"
        stroke="currentColor"
        opacity={0.2}
        strokeWidth={strokeWidth}
      />
      <g transform={`translate(${size / 2},${size / 2})`}>
        <path d={d ?? ''} fill="currentColor" opacity={0.5} />
      </g>
    </svg>
  );
};
