import React, { useMemo } from 'react';

import { DeviceData } from '../../api/types';
import { routes } from '../../routes';
import { DEVICE_LIST_COLUMNS } from '../../utils/deviceLists';
import { isDefinedAndNonEmpty } from '../../utils/isDefined';
import { Table } from '../Table/Table';

export const DeviceListWidget = ({ devices }: { devices: DeviceData[] }) => {
  const columns = useMemo(DEVICE_LIST_COLUMNS, []);

  return (
    <Table
      columns={columns}
      data={devices}
      linkProps={(device) =>
        isDefinedAndNonEmpty(device.name)
          ? {
              to: {
                main: routes.network.devices.list.path,
                drawer: routes.drawers.devices.detail.pathTo(device.name),
              },
            }
          : null
      }
      emptyStateHeading="No devices"
    />
  );
};
