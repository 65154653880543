import { Skeleton } from '@meterup/metric';
import React from 'react';

import { styled } from '../../stitches';
import {
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
} from '../Drawer/Drawer';

const Container = styled('div', { vStack: '$16', alignItems: 'stretch' });

export interface LoadingDrawerProps {
  title?: React.ReactNode;
  skeletonCount?: number;
}

export const LoadingDrawer = ({
  title = <Skeleton height="28px" width="200px" radius={4} />,
  skeletonCount = 1,
}: LoadingDrawerProps) => (
  <Drawer>
    <DrawerHeader>
      <DrawerTitle>{title}</DrawerTitle>
      <DrawerControls>
        <CloseDrawerButton />
      </DrawerControls>
    </DrawerHeader>
    <DrawerContent>
      <Container>
        {new Array(skeletonCount).fill(0).map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Skeleton key={i} width="100%" height="88px" radius={4} />
        ))}
      </Container>
    </DrawerContent>
  </Drawer>
);
