/**
 * TODO: Replace this file with simple state scoped to the VPN components. We
 * don't need reducers here!!
 */

import React, { createContext, Dispatch, Reducer, useReducer } from 'react';

import { FixMeLater } from '../types/fixMeLater';

export interface VPNState {
  vpnClients?: FixMeLater;
}

const EMPTY_STATE: VPNState = {};

export const VPNContext = createContext<[VPNState, Dispatch<VPNAction>]>(null as any);

enum ActionType {
  SET_VPN_CLIENTS = `SET_VPN_CLIENTS`,
}

type SetVPNClientsAction = {
  type: ActionType.SET_VPN_CLIENTS;
  payload: FixMeLater;
};

type VPNAction = SetVPNClientsAction;

export const storeVPNClients = (data: FixMeLater): SetVPNClientsAction => ({
  type: ActionType.SET_VPN_CLIENTS,
  payload: data,
});

const reducer: Reducer<VPNState, VPNAction> = (state, action) => {
  switch (action.type) {
    case ActionType.SET_VPN_CLIENTS:
      return { ...state, vpnClients: action.payload };
    default:
      return state;
  }
};

export const VPNContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, EMPTY_STATE);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <VPNContext.Provider value={[state, dispatch]}>{children}</VPNContext.Provider>;
};
