import { useRootLocation } from '@meterup/react-router-extensions';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export const useCloseDrawerCallback = () => {
  const history = useHistory();
  const location = useRootLocation();

  return useCallback(() => {
    const newSearch = new URLSearchParams(location.search);
    newSearch.delete('drawer');

    history.push({
      ...location,
      search: newSearch.toString(),
    });
  }, [history, location]);
};
