import { Body2, FocusRingSelf, Icon, space } from '@meterup/metric';
import { mergeProps } from '@meterup/react-aria';
import React, { HTMLProps } from 'react';

import { colors, fontWeights, shadows, styled } from '../../stitches';

const StepButtonBase = styled('button', FocusRingSelf, {
  display: 'flex',
  gap: '$12',
  position: 'relative',
  width: '100%',
  borderRadius: '$8',
  background: colors.white,
  boxShadow: shadows.controlTertiaryInitialLight,
  alignItems: 'stretch',
  padding: '$12 $20',
  '&:before': {
    content: '',
    position: 'absolute',
    top: '$4',
    bottom: '$4',
    width: '$4',
    left: '$4',
    background: '$$stripColor',
    borderRadius: 999,
  },
  variants: {
    status: {
      complete: {
        $$stripColor: colors['green-700'],
        background: colors['gray-50'],
        boxShadow: 'none',
      },
      selected: {
        $$stripColor: colors['brand-500'],
      },
      pending: {
        $$stripColor: colors['gray-600'],
      },
    },
  },
});

const StepButtonContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const StepButtonTitle = styled('div', Body2, {
  fontWeight: fontWeights.medium,
  color: colors['gray-700'],
});

const StepButtonDescription = styled('div', Body2, {
  textAlign: 'left',
  color: colors['gray-500'],
});

const StepButtonIcon = styled(Icon, {
  color: colors['gray-600'],
  // KLUDGE: Align icon with title by nudging it down
  marginTop: '$2',
});

const StepButtonChevron = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: colors['gray-600'],
  marginLeft: 'auto',
});

export interface StepButtonProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  status?: 'complete' | 'selected' | 'pending';
  icon?: 'checkmark' | 'attention';
}

export const StepButton = React.forwardRef<
  HTMLButtonElement,
  StepButtonProps & HTMLProps<HTMLButtonElement>
  // eslint-disable-next-line react/prop-types
>(({ title, description, status = 'pending', icon, ...props }, ref) => (
  <StepButtonBase {...mergeProps(props as any, { status })} ref={ref}>
    {icon && <StepButtonIcon icon={icon} size={space(16)} />}
    <StepButtonContent>
      <StepButtonTitle>{title}</StepButtonTitle>
      {description && <StepButtonDescription>{description}</StepButtonDescription>}
    </StepButtonContent>
    <StepButtonChevron>
      <Icon icon="chevronRight" size={space(12)} />
    </StepButtonChevron>
  </StepButtonBase>
));
