import { Body, Caption, HStack, List, ListItem, space, Text, VStack } from '@meterup/metric';
import React from 'react';
import { Link } from 'react-router-dom';

import { routes } from '../../routes';
import formatTime from '../../utils/formatTime';
import { BandwidthGauge } from '../BandwidthGauge/BandwidthGauge';

export const SpeedTestWidget = (props: {
  bandwidthUpdatedAt: Date | null;
  downloadBytes: number;
  uploadBytes: number;
}) => (
  <List>
    <ListItem>
      <HStack align="center" justify="between" width="full">
        <Body weight="medium">Speed test</Body>
        {props.bandwidthUpdatedAt && (
          <Caption>
            Last updated: <Text weight="medium">{formatTime(props.bandwidthUpdatedAt)}</Text>
          </Caption>
        )}
      </HStack>
    </ListItem>
    <ListItem as={Link} to={routes.network.internetWiFi.path}>
      <VStack width="full" spacing={space(12)}>
        <HStack spacing={space(12)}>
          <BandwidthGauge speedBytes={props.downloadBytes} direction="download" />
          <BandwidthGauge speedBytes={props.uploadBytes} direction="upload" />
        </HStack>
      </VStack>
    </ListItem>
  </List>
);
