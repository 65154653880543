import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { useCurrentCompanyLocation } from '../../useCurrentCompanyLocation';
import { steps } from '../steps';
import { OnboardingStepKey } from '../types';
import { useCurrentOnboardingStep } from './useCurrentOnboardingStep';

export const getNextStepKey = (step: OnboardingStepKey): OnboardingStepKey =>
  step < 4 ? step + 1 : step;

export const getPrevStepKey = (step: OnboardingStepKey): OnboardingStepKey =>
  step > 0 ? step - 1 : step;

export const useOnboardingNavigation = () => {
  const history = useHistory();
  const step = useCurrentOnboardingStep();
  const { sublocation_sid: sublocationSid } = useCurrentCompanyLocation() ?? {};

  const goToNextStep = useCallback(() => {
    if (step && sublocationSid) {
      history.push(steps[getNextStepKey(step.key)].route.pathTo(sublocationSid));
    }
  }, [step, history, sublocationSid]);

  const goBack = useCallback(() => {
    if (step != null && sublocationSid) {
      history.push(steps[getPrevStepKey(step.key)].route.pathTo(sublocationSid));
    }
  }, [step, history, sublocationSid]);

  return { goToNextStep, goBack };
};
