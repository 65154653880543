import { DeviceIcon, DeviceIconSize } from '@meterup/metric';
import React from 'react';
import { Column } from 'react-table';

import { DeviceData } from '../api/types';
import { CellDeviceStatus } from '../components/Table/tableCells';
import { styled } from '../stitches';

const DeviceName = styled('div', { hStack: '$8' });

export const DEVICE_LIST_COLUMNS = (): Column<DeviceData>[] => [
  {
    Header: 'Name',
    accessor: (device) => device.physical_location ?? device.name,
    Cell: ({ value }: { value: string }) => (
      <DeviceName>
        <DeviceIcon size={DeviceIconSize.Small} /> {value}
      </DeviceName>
    ),
  },
  {
    Header: 'Status',
    accessor: (device) => device.status,
    Cell: CellDeviceStatus,
  },
  {
    Header: 'Clients',
    accessor: (device) => device.clients,
  },
];
