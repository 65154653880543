import { ProductIconProduct } from '@meterup/metric';

import { FeatureFlaggable } from '../../feature_flags';
import { routes } from '../../routes';

type ActiveKey = 'network' | 'vpn' | 'settings';

export type NavigationEntry = {
  label: string;
  path: string;
  icon: ProductIconProduct;
  key: ActiveKey;
} & FeatureFlaggable;

export const getActiveKey = (location: string): ActiveKey => {
  if (location.startsWith('/settings')) {
    return 'settings';
  }

  if (location.startsWith('/vpn')) {
    return 'vpn';
  }

  return 'network';
};

export const appNavigationEntries: NavigationEntry[] = [
  {
    label: 'Network',
    icon: 'network',
    path: routes.network.overview.path,
    key: 'network',
  },
  {
    featureFlag: 'vpn-ui',
    label: 'VPN',
    icon: 'vpn',
    path: routes.vpn.overview.path,
    key: 'vpn',
  },
  {
    featureFlag: 'meter-auth-ui',
    label: 'Settings',
    icon: 'settings',
    path: routes.settings.users.list.path,
    key: 'settings',
  },
];
