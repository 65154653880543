import { Heading2, RadialGauge, Small2 } from '@meterup/metric';
import React from 'react';

import {
  BYTES_PER_GIGABIT,
  formatBytesAsBpsLong,
  formatBytesAsBpsParts,
} from '../../utils/networkSpeedUtils';

export interface BandwidthGaugeProps {
  speedBytes: number;
  direction: 'download' | 'upload';
}

export const BandwidthGauge: React.FunctionComponent<BandwidthGaugeProps> = ({
  speedBytes,
  direction,
}) => {
  const parts = formatBytesAsBpsParts(speedBytes, 1);
  const valueLabel = formatBytesAsBpsLong(speedBytes, 1);

  return (
    <RadialGauge
      minValue={0}
      maxValue={BYTES_PER_GIGABIT}
      value={speedBytes}
      aria-label={`${direction} speed`}
      valueLabel={valueLabel}
    >
      {parts && (
        <>
          <Heading2>{parts.formattedValue}</Heading2>
          <Small2>
            {parts.size.shortText} {direction}
          </Small2>
        </>
      )}
    </RadialGauge>
  );
};
