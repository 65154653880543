import { api } from '@meterup/proto';

export type ServiceProviderType = 'builtin' | 'other';

export type ServiceProviderData =
  | {
      type: ServiceProviderType;
      providerSid: string;
    }
  | {
      type: 'no-isp';
    };

export type MainNetworkData = {
  ssid: string;
  password: string;
};

export type FrequencyInterval = 'hour' | 'day' | 'week';

export type GuestNetworkData = {
  ssid: string;
  password:
    | {
        type: 'static';
        value: string;
      }
    | {
        type: 'rotate';
        frequency: {
          unit: FrequencyInterval;
          value: number;
        };
      };
};

export type FlatGuestNetworkData = {
  ssid: string;
  password: string;
  autoRotatePassword: boolean;
  rotateEveryValue: number;
  rotateEveryUnit: FrequencyInterval;
};

export type BuildingData = {
  contactType: api.BuildingContactType;
  email: string;
  tel: string;
};

export type BillingData = {
  email: string;
};

export interface OnboardingFormData {
  serviceProviderData: ServiceProviderData;
  mainNetworkData: MainNetworkData;
  guestNetworkData: FlatGuestNetworkData;
  buildingData: BuildingData;
  billingData: BillingData;
}

export enum OnboardingStepKey {
  ISPDetails,
  NetworkSetup,
  GuestNetwork,
  MeterInstall,
}
