import { ListItemLabel, ListItemPair, ListItemValue } from '@meterup/metric';
import React, { ReactNode } from 'react';

import { styled } from '../../../stitches';
import { PasswordViewer } from '../../PasswordViewer';

const TruncatingLabel = styled(ListItemLabel, {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  minWidth: 0,
});

export const PasswordViewerListItem = ({
  password,
  label,
}: {
  password: string;
  label: ReactNode;
}) => (
  <ListItemPair>
    <TruncatingLabel>{label}</TruncatingLabel>
    <ListItemValue>
      <PasswordViewer password={password} />
    </ListItemValue>
  </ListItemPair>
);
