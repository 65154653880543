import { activeThemeSelector, Small2 } from '@meterup/metric';
import { api } from '@meterup/proto';
import React from 'react';

import { colors, fontWeights, styled } from '../stitches';
import { isDefined } from '../utils/isDefined';

const Container = styled('div', Small2, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$20',
  height: '$20',
  overflow: 'hidden',
  color: '$$foregroundColor',
  fontWeight: fontWeights.medium,
  [activeThemeSelector]: {
    $$backgroundColor: colors.white,
  },
  variants: {
    size: {
      small: {
        width: '$20',
        height: '$20',
        letterSpacing: '-0.05em',
        fontSize: 10,
      },
      medium: {
        width: '$28',
        height: '$28',
        fontSize: 12,
      },
      large: {
        width: '$64',
        height: '$64',
        fontSize: 24,
      },
    },
    color: {
      brand: {
        $$backgroundColor: colors['brand-50'],
        $$foregroundColor: colors['brand-800'],
      },
      blue: {
        $$backgroundColor: colors['blue-50'],
        $$foregroundColor: colors['blue-800'],
      },
      green: {
        $$backgroundColor: colors['green-50'],
        $$foregroundColor: colors['green-800'],
      },
      red: {
        $$backgroundColor: colors['red-50'],
        $$foregroundColor: colors['red-800'],
      },
    },
  },
});

const StyledSVG = styled('svg', {
  position: 'absolute',
  inset: 0,
  fill: '$$backgroundColor',
  zIndex: 0,
});

const Initials = styled('span', {
  zIndex: 1,
});

const getInitials = (user: api.UserResponse) =>
  isDefined(user.first_name) && isDefined(user.last_name)
    ? user.first_name[0] + user.last_name[0]
    : user.email.slice(0, 2);

const colorSets = ['brand', 'blue', 'green', 'red'] as const;

const getColor = (user: api.UserResponse) => colorSets[user.email.charCodeAt(0) % 4];

export const UserProfilePicture = ({
  user,
  size = 'small',
}: {
  user: api.UserResponse;
  size?: 'small' | 'medium' | 'large';
}) => {
  const color = getColor(user);
  return (
    <Container color={color} size={size}>
      <StyledSVG viewBox="0 0 20 20">
        <path d="M20 10C20 6.58894 19.7675 2.74054 18.5175 1.48688C17.2675 0.233235 12.7907 0 10.0291 0C7.26743 0 2.79072 0.233235 1.5407 1.48688C0.290696 2.74054 0 6.58894 0 10C0 13.4111 0.2907 17.2595 1.5407 18.5131C2.79072 19.7668 7.26743 20 10.0291 20C12.7907 20 17.2674 19.7668 18.5174 18.5131C19.7675 17.2595 20 13.4111 20 10Z" />
      </StyledSVG>
      <Initials>{getInitials(user)}</Initials>
    </Container>
  );
};
