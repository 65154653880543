import { createLocation, Location, LocationDescriptor } from 'history';
import { match, matchPath } from 'react-router';

export type To<S> = LocationDescriptor<S> | ((location: Location<S>) => LocationDescriptor<S>);

export const resolveToLocation = (to: To<unknown>, currentLocation: Location<unknown>) =>
  typeof to === 'function' ? to(currentLocation) : to;

export const normalizeToLocation = (
  to: LocationDescriptor<unknown>,
  currentLocation: Location<unknown> | undefined,
) => (typeof to === 'string' ? createLocation(to, null, undefined, currentLocation) : to);

export interface PathMatchOptions {
  /**
   * When true, will only match if the path matches the location.pathname exactly.
   */
  exact?: boolean;

  /**
   * When true, will match if the path is case sensitive.
   */
  sensitive?: boolean;

  /**
   * When true, a path that has a trailing slash will only match a location.pathname with a trailing
   * slash. This has no effect when there are additional URL segments in the location.pathname.
   */
  strict?: boolean;
}

/**
 * Matches the path described by the `to` parameter relative to the given `location` parameter.
 */
export const matchPathFromLocation = (
  to: To<unknown>,
  location: Location<unknown>,
  { exact, sensitive, strict }: PathMatchOptions = {},
): match<{}> | null => {
  const resolvedLocation = resolveToLocation(to, location);
  const toLocation = normalizeToLocation(resolvedLocation, location);
  const { pathname: path } = toLocation;

  return matchPath(location.pathname, {
    path,
    exact,
    sensitive,
    strict,
  });
};
