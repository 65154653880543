import {
  BasicSelect,
  BasicSelectItem,
  Button,
  CompositeField,
  Heading,
  HStack,
  List,
  PrimaryField,
  SecondaryField,
  SecondaryFieldComposite,
  space,
  TextInput,
  ToggleInput,
  VStack,
} from '@meterup/metric';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';

import { getFieldAndErrorProps } from '../../../../utils/getFieldAndErrorProps';
import { BackButton } from '../components/BackButton';
import { NextStepButton } from '../components/NextStepButton';
import { PasswordStrengthIndicator } from '../components/PasswordStrengthIndicator';
import { WordCountListItem } from '../components/WordCountListItem';
import { useSuggestPasswordState } from '../hooks/useSuggestPasswordState';
import { FrequencyInterval, OnboardingFormData } from '../types';

interface RotationIntervalOption {
  key: FrequencyInterval;
  name: string;
}

const ROTATION_INTERVAL_OPTIONS: RotationIntervalOption[] = [
  { key: 'hour', name: 'Hours' },
  { key: 'day', name: 'Days' },
  { key: 'week', name: 'Weeks' },
];

export const GuestNetworkStep: React.FC = () => {
  const form = useFormikContext<OnboardingFormData>();

  // Automatically populate the SSID with "<main network SSID> Guest"
  useEffect(() => {
    if (
      form.values.guestNetworkData.ssid === '' &&
      form.values.mainNetworkData.ssid !== '' &&
      !form.touched.guestNetworkData?.ssid
    ) {
      form.setFieldValue('guestNetworkData.ssid', `${form.values.mainNetworkData.ssid} Guest`);
    }
  }, [form]);

  const onSetPassword = useCallback(
    (pwd: string) => {
      form.setFieldValue('guestNetworkData.password', pwd);
    },
    [form],
  );

  const { wordCount, setWordCount, refreshPassword } = useSuggestPasswordState(onSetPassword);

  return (
    <VStack spacing={space(16)}>
      <Heading>Guest network</Heading>
      <List>
        <PrimaryField
          InputComponent={TextInput}
          label="Guest SSID"
          autoComplete="off"
          autoFocus
          {...getFieldAndErrorProps(form, 'guestNetworkData.ssid')}
        />
      </List>
      <List>
        <PrimaryField
          InputComponent={TextInput}
          label="Guest password"
          autoComplete="off"
          autoFocus
          controls={
            <>
              <PasswordStrengthIndicator password={form.values.guestNetworkData.password ?? ''} />
              <Button
                size="small"
                icon="arrowRotate"
                type="button"
                arrangement="hidden-label"
                variant="secondary"
                onClick={refreshPassword}
              >
                Suggest a password
              </Button>
            </>
          }
          {...getFieldAndErrorProps(form, 'guestNetworkData.password')}
        />
        <WordCountListItem value={wordCount} onChange={setWordCount} />
        <SecondaryField
          label="Auto-rotate password"
          InputComponent={ToggleInput}
          selected={form.values.guestNetworkData.autoRotatePassword}
          {...getFieldAndErrorProps(form, 'guestNetworkData.autoRotatePassword')}
        />
        {form.values.guestNetworkData.autoRotatePassword && (
          <SecondaryFieldComposite
            label="Rotate every"
            fields={
              <>
                <CompositeField
                  InputComponent={TextInput}
                  label="Password rotation frequency"
                  type="number"
                  {...({ min: 1 } as any)}
                  {...getFieldAndErrorProps(form, 'guestNetworkData.rotateEveryValue')}
                />
                <CompositeField
                  InputComponent={BasicSelect}
                  label="Password rotation interval"
                  {...getFieldAndErrorProps(form, 'guestNetworkData.rotateEveryUnit')}
                >
                  {ROTATION_INTERVAL_OPTIONS.map((item) => (
                    <BasicSelectItem key={item.key} value={item.key}>
                      {item.name}
                    </BasicSelectItem>
                  ))}
                </CompositeField>
              </>
            }
          />
        )}
      </List>
      <HStack justify="between">
        <BackButton />
        <NextStepButton requireValidationAtPath="guestNetworkData" />
      </HStack>
    </VStack>
  );
};
