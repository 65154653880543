import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { createQRCode, createWGConf } from '../../utils/vpnUtils';
import vpnStyles from './styles';

const VPNClientCreateResults = ({ privateKey, deviceType, client, server }) => {
  const classes = vpnStyles();
  const [wgConf, setWgConf] = useState();
  const [qrCode, setQrCode] = useState();
  const [downloadUrl, setDownloadUrl] = useState();

  useEffect(() => {
    if (!client) {
      return;
    }

    const conf = createWGConf(client.ip_address, server.endpoint, server.public_key, privateKey);
    setWgConf(conf);

    const doQrCode = async () => {
      setQrCode(await createQRCode(conf));
    };
    // if there's no private key, we can't generate a scanable qr code
    if ((deviceType === 'iOS' || deviceType === 'Android') && privateKey) {
      doQrCode();
    } else {
      const blob = new Blob([conf], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      setDownloadUrl(url);
    }
  }, [client, deviceType, privateKey, server.endpoint, server.public_key]);

  if (!client) {
    return null;
  }

  if (qrCode) {
    return (
      <Box className={classes.resultsContainer}>
        <Box>Scan this code on the WireGuard app</Box>
        <img src={qrCode} alt="qrcode" />
      </Box>
    );
  }

  return (
    <Box className={classes.resultsContainer}>
      <pre>
        <code>{wgConf}</code>
      </pre>
      <a className={classes.createButton} download="meter-vpn.conf" href={downloadUrl}>
        Download
      </a>
    </Box>
  );
};

VPNClientCreateResults.propTypes = {
  privateKey: PropTypes.string,
  deviceType: PropTypes.string,
  client: PropTypes.instanceOf(Object).isRequired,
  server: PropTypes.instanceOf(Object).isRequired,
};

VPNClientCreateResults.defaultProps = {
  privateKey: null,
  deviceType: '',
};

export default VPNClientCreateResults;
