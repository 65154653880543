import { Subheading2 } from '@meterup/metric';

import { colors, shadows, styled } from '../../stitches';

export const Container = styled('div', {
  height: '100%',
  display: 'grid',
  position: 'relative',
  gridTemplateRows: 'min-content 1fr min-content',
  variants: {
    layout: {
      'main-only': {
        gridTemplateColumns: '1fr',
        gridTemplateAreas: '"header" "main" "footer"',
      },
      'main-and-drawer': {
        gridTemplateColumns: '1fr minmax(240px, 390px)',
        gridTemplateAreas: '"header header" "main drawer" "footer drawer"',
      },
    },
  },
});

export const Main = styled('div', {
  paddingY: '$40',
  paddingX: '$20',
  gridArea: 'main',
  zIndex: 2,
});

export const Header = styled('div', {
  background: colors.white,
  gridArea: 'header',
  boxShadow: shadows.fenceBottomLight,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '$40',
  zIndex: 3,
});

export const MainContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$16',
  maxWidth: 480,
  margin: '0 auto',
});

export const Drawer = styled('div', {
  boxShadow: shadows.fenceLeftLight,
  gridArea: 'drawer',
});

export const DrawerHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '$16 $20',
  borderBottom: `1px solid ${colors['gray-100']}`,
});

export const DrawerTitle = styled('h2', Subheading2, {});

export const DrawerControls = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$8',
});

export const DrawerContent = styled('div', {
  padding: '$16 $20',
});

export const FooterContent = styled('div', {
  gridArea: 'footer',
  width: '100%',
  padding: '$40 0',
  maxWidth: 480,
  margin: '0 auto',
});
