import { Body2 } from '@meterup/metric';

import { colors, fontWeights, shadows, styled } from '../../stitches';

export const Page = styled('div', {
  vStack: '$16',
  alignItems: 'stretch',
});

export const PageHeader = styled('div', {
  hStack: '$8',
  paddingX: '$20',
  height: '$56',
  boxShadow: shadows.fenceBottomLight,
});

export const PageTitle = styled('h1', Body2, {
  color: colors['gray-800'],
  fontWeight: fontWeights.medium,
});

export const PageSection = styled('div');

export const PageControls = styled('div', {
  hStack: '$8',
  marginLeft: 'auto',
});

export const OldPageContainer = styled('div', {
  padding: '$20',
});
