import { useRootLocation } from '@meterup/react-router-extensions';
import { LocationDescriptorObject, LocationState, Path } from 'history';
import React, { AnchorHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

import {
  buildLocationDescriptorForMainAndDrawer,
  isMainAndDrawerLocationDescriptor,
  MainAndDrawerLocationDescriptor,
} from '../../utils/main_and_drawer_navigation';

export interface SmartLinkNavigationProps<S = LocationState> {
  to: Path | LocationDescriptorObject<S> | MainAndDrawerLocationDescriptor<S>;
  replace?: boolean;
}

export interface SmartLinkProps<S = LocationState>
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    SmartLinkNavigationProps<S> {}

/**
 * A component that wraps the Link component from react-router-dom and can
 * simultaneously navigate to a `main` and `drawer` path.
 */
export const SmartLink = React.forwardRef(
  <S extends any = LocationState>(
    { to, replace = false, children, ...props }: SmartLinkProps<S>,
    forwardedRef: React.ForwardedRef<HTMLAnchorElement>,
  ) => {
    const location = useRootLocation();

    if (isMainAndDrawerLocationDescriptor(to)) {
      return (
        <Link
          {...props}
          ref={forwardedRef}
          to={buildLocationDescriptorForMainAndDrawer(to, location)}
          replace={replace}
        >
          {children}
        </Link>
      );
    }

    return (
      <Link {...props} ref={forwardedRef} to={to} replace={replace}>
        {children}
      </Link>
    );
  },
);
