import { DesktopSidebar, DesktopSidebarNavItem } from '@meterup/metric';
import React from 'react';

import { useLogoutHandler } from '../../hooks/useLogoutHandler';
import { styled } from '../../stitches';
import { ScopedDesktopSidebarEntries } from './ScopedDesktopSidebar';
import { settingsSidebarEntries } from './sidebarEntries';

const LogoutButton = styled('div', { marginTop: 'auto', width: '100%', padding: '$12' });

const SettingsSidebarDesktop: React.FC = () => {
  const logout = useLogoutHandler();
  return (
    <DesktopSidebar>
      <ScopedDesktopSidebarEntries entries={settingsSidebarEntries} />
      <LogoutButton>
        <DesktopSidebarNavItem as="button" label="Log out" onClick={logout} />
      </LogoutButton>
    </DesktopSidebar>
  );
};

export default SettingsSidebarDesktop;
