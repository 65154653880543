import { makeStyles } from '@material-ui/core/styles';

const vpnStyles = makeStyles((theme) => ({
  '@keyframes arrowEffect': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  headerWrapper: {
    display: 'flex',
    '& a': {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: `${theme.colors.lightPurple}`,
      borderRadius: '6px',
      padding: '4px 10px 2px 10px',
      margin: '0 10px',
      maxHeight: 25,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.colors.lightPurple,
        '& span': {
          color: theme.colors.purple,
        },
      },
    },
  },
  itemRowDevice: {
    fontSize: '0.9rem',
    alignItems: 'center',
    borderStyle: 'solid',
    borderColor: `transparent transparent ${theme.colors.lightPurple}`,
    borderRadius: 6,
    borderWidth: 1,
    cursor: 'pointer',
    display: 'grid',
    gridAutoColumns: '1fr',
    gap: 14,
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridTemplateRows: 'auto',
    minHeight: 54,
    margin: '0 15px -1px 15px',
    position: 'relative',
    padding: '7px 18px 7px 18px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '2fr 1fr',
    },
    '& img.arrow': {
      maxWidth: 14,
      maxHeight: 14,
    },
    '& a': {
      display: 'contents',
      textDecoration: 'none',
    },
    '& div': {
      display: 'flex',
      overflowWrap: 'anywhere',
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    '& div:nth-child(n+3)': {
      paddingLeft: '1.8em',
    },
    '& div:first-child': {
      fontWeight: 500,
    },
    '& div:last-child': {
      wordBreak: 'break-all',
    },
    '& div img.arrow': {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: theme.colors.lightGray,
      borderColor: 'transparent',
      borderRadius: 6,
    },
    '&:hover div:last-child img.arrow': {
      display: 'inline-block',
      position: 'absolute',
      right: 10,
      top: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1,
    },
  },
  mainContainer: {
    width: '100%',
    padding: '0 14px',
    '& ul': {
      listStyleType: 'none',
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 10,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 34,
      flexDirection: 'column',
      padding: 0,
    },
    '& h2': {
      fontSize: '1.7rem',
      lineHeight: 1.2,
    },
  },
  contentContainer: {
    marginTop: 50,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  goBackLink: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textDecoration: 'none',
    '&:hover': {
      '& $link': {
        animation: '$arrowEffect 1.2s linear infinite',
      },
    },
    '& p': {
      alignItems: 'center',
      color: theme.colors.purple,
      fontWeight: '400',
      fontSize: '0.9375rem',
      marginLeft: 8,
      '&:hover': {
        color: theme.colors.darkGray,
      },
    },
    [theme.breakpoints.down('xs')]: {
      borderBottom: `1px solid ${theme.colors.lightPurple}`,
      justifyContent: 'left',
      paddingBottom: 12,
      position: 'absolute',
      top: 80,
      width: '87%',
    },
  },
  inputRoot: {
    border: '1px solid rgb(236, 237, 255)',
    borderRadius: 5,
    padding: '12px 5px 9px 5px',
    marginTop: 15,
    width: 440,
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    '& input': {
      padding: '0 !important',
    },
    '& input::placeholder': {
      fontSize: '0.87rem',
      color: theme.colors.dark,
    },
    '&:focus': {
      backgroundColor: theme.colors.lightGray,
      boxShadow: 'none',
    },
    '& + &': {},
  },
  select: {
    marginTop: 15,
    width: 440,
    border: theme.border,
    borderRadius: 5,
    paddingLeft: 4,
    paddingTop: 9,
    paddingBottom: 2,
    fontSize: '0.87rem',
    color: theme.colors.dark,
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  paperClass: {
    marginTop: '-3px',
    marginLeft: '-3px',
  },
  deviceTypeOption: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  options: {
    padding: '5px 9px',
    borderRadius: 5,
    margin: '0 8px 0 14px',
    display: 'block',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      backgroundColor: `${theme.colors.lightPurple}!important`,
      color: theme.colors.purple,
    },
  },
  createButton: {
    marginTop: 15,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.colors.lightPurple,
    borderRadius: '6px',
    padding: '8px 9px 5px 9px',
    margin: '0 10px',
    maxHeight: 43,
    textTransform: 'none',
    backgroundColor: theme.colors.lightPurple,
    color: theme.colors.purple,
    '&:hover': {
      backgroundColor: theme.colors.purple,
      '& span': {
        color: theme.colors.lightPurple,
      },
    },
  },
  resultsContainer: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
    '& a': {
      textDecoration: 'none',
      textAlign: 'center',
    },
  },
  vpnClientDetails: {
    marginTop: 20,
  },
  error: {
    marginTop: 5,
    fontSize: '0.87rem',
    color: theme.colors.purple,
  },
  instructions: {
    marginTop: 20,
    '& h2': {
      fontSize: '1.5rem',
    },
    '& a': {
      color: theme.colors.purple,
      fontWeight: 400,
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: theme.colors.lightPurple,
        color: theme.colors.livePurple,
      },
    },
  },
  link: {},
}));

export default vpnStyles;
