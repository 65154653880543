import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { Link, useHistory } from 'react-router-dom';
import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import vpnStyles from './styles';
import arrowGrey from '../../assets/images/icons/arrowGrey.svg';
import arrowBlue from '../../assets/images/icons/arrowBlue.svg';
import { createVPNClient } from '../../api/api';
import { generateKeyPair } from '../../utils/vpnUtils';
import VPNClientCreateResults from './VPNClientCreateResults';
import { useCurrentController } from '../../providers/CurrentControllerProvider';

const deviceOptions = ['macOS', 'Windows', 'iOS', 'Android', 'Linux', 'Other'];

const VPNClientCreate = () => {
  const classes = vpnStyles();
  const [img, setImg] = useState(arrowBlue);
  const [publicKey, setPublicKey] = useState('');
  const [generatedKeys, setGeneratedKeys] = useState();
  const [deviceName, setDeviceName] = useState('');
  const [deviceType, setDeviceType] = useState('deviceType');
  const [vpnClient, setVpnClient] = useState();
  const [vpnServer, setVpnServer] = useState();
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const controller = useCurrentController();
  const goBack = () => {
    history.push('/vpn');
  };

  const validate = () => {
    if (publicKey.length > 0 && publicKey.length !== 44) {
      setErrors((prevState) => ({
        ...prevState,
        publicKey: 'Public key must be 44 characters',
      }));
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    setErrors({});
    const valid = validate();
    if (!valid) {
      return;
    }
    setGeneratedKeys(null);
    let keys;
    if (!publicKey || publicKey === '') {
      keys = generateKeyPair();
      setGeneratedKeys(keys);
      setPublicKey(keys.publicKey);
    }
    const { client, server } = await createVPNClient(
      controller,
      keys?.publicKey || publicKey,
      deviceName,
    );
    setVpnClient(client);
    setVpnServer(server);
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.titleContainer}>
        <h2>Create New Device</h2>
        <Link
          onClick={goBack}
          onMouseEnter={() => {
            setImg(arrowGrey);
          }}
          onMouseLeave={() => {
            setImg(arrowBlue);
          }}
          to="/vpn"
          className={classes.goBackLink}
        >
          <img className={classes.link} src={img} alt="Go To" />
          <p>Back to VPN</p>
        </Link>
      </Box>
      <Box className={classes.instructions}>
        <h2>Instructions</h2>
        <p>
          Install{' '}
          <a href="https://www.wireguard.com/install/" target="_blank" rel="noreferrer">
            WireGuard
          </a>
        </p>
        <p>
          You can generate your own WireGuard public key or have one autogenerated (should reword
          this and needs more detail).
        </p>
      </Box>
      <Box className={classes.contentContainer}>
        <InputBase
          placeholder="Public Key"
          className={classes.inputRoot}
          value={publicKey}
          onChange={(e) => {
            setPublicKey(e.target.value);
          }}
          disabled={!!vpnClient}
        />
        <div className={classes.error}>{errors.publicKey}</div>
        <InputBase
          placeholder="Device Name"
          className={classes.inputRoot}
          value={deviceName}
          onChange={(e) => {
            setDeviceName(e.target.value);
          }}
          disabled={!!vpnClient}
        />
        <Select
          MenuProps={{
            classes: {
              paper: classes.paperClass,
            },
            disableScrollLock: true,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            getContentAnchorEl: null,
          }}
          className={`${classes.select} ${deviceType === 'deviceType' && classes.deviceTypeOption}`}
          value={deviceType}
          onChange={(e) => {
            setDeviceType(e.target.value);
          }}
          disabled={!!vpnClient}
        >
          <MenuItem className={classes.options} key="deviceType" value="deviceType" disabled>
            <Box>Device Type</Box>
          </MenuItem>
          {deviceOptions.map((option) => (
            <MenuItem className={classes.options} key={option} value={option}>
              <Box>{option}</Box>
            </MenuItem>
          ))}
        </Select>
        <Button
          className={classes.createButton}
          onClick={handleSubmit}
          disabled={!!vpnClient || deviceType === 'deviceType' || deviceName.length < 1}
        >
          Create Device
        </Button>
        {vpnClient && vpnServer && (
          <VPNClientCreateResults
            privateKey={generatedKeys?.privateKey}
            deviceType={deviceType}
            client={vpnClient}
            server={vpnServer}
          />
        )}
      </Box>
    </Box>
  );
};

export default VPNClientCreate;
