import {
  Badge,
  Body,
  DeviceIcon,
  DeviceIconSize,
  Heading2,
  List,
  ListItem,
  ListItemNavigationArrow,
} from '@meterup/metric';
import { sortBy } from 'lodash';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { fetchClientConnectionHistory, fetchDevice, getUser } from '../../api/api';
import { NotFoundError } from '../../errors';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../providers/CurrentControllerProvider';
import { routes } from '../../routes';
import { styled } from '../../stitches';
import { expectDefinedOrThrow } from '../../utils/expectDefinedOrThrow';
import { isDefined } from '../../utils/isDefined';
import { formatFullName } from '../../utils/names';
import { onlineOfflineBadgeVariant } from '../badges';
import {
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
} from '../Drawer/Drawer';
import { SmartLink } from '../SmartLink/SmartLink';
import { UserProfilePicture } from '../UserProfilePicture';
import { ClientSummaryWidget } from '../Widgets/ClientSummaryWidget';
import { ConnectionDetailsWidget } from '../Widgets/ConnectionDetailsWidget';
import { WirelessConnectionHistoryWidget } from '../Widgets/WirelessConnectionHistoryWidget';

const DRAWER_TITLE = routes.drawers.clients.detail.label;

const ClientName = styled('div', {
  textAlign: 'center',
});

const NavIcon = styled('div', { display: 'flex', marginLeft: 'auto' });

export const ClientDetailDrawer = () => {
  const controller = useCurrentController();
  const { macAddress } = useParams<{ macAddress: string }>();

  const { data: clientHistory } = useQuery(
    ['client_history', macAddress],
    () => fetchClientConnectionHistory(controller, macAddress),
    {
      suspense: true,
    },
  );

  const client = sortBy(clientHistory ?? [], 'last_seen').reverse()[0] ?? null;

  expectDefinedOrThrow(client, new NotFoundError('Client not found'));

  const { data: device } = useQuery(
    ['device', controller, client?.apname],
    () => fetchDevice(controller, client!.apname),
    {
      suspense: true,
    },
  );

  const companySlug = useCurrentCompany();
  const { data: user } = useQuery(
    ['user', client.user_sid],
    () => getUser(companySlug, client.user_sid!),
    { suspense: true, enabled: isDefined(client.user_sid) },
  );

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>{DRAWER_TITLE}</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <ClientName>
          <Heading2>{client.name}</Heading2>
        </ClientName>
        {[device, user].some(isDefined) && (
          <List>
            {isDefined(user) && (
              <ListItem
                as={SmartLink}
                to={{
                  main: routes.settings.users.list.path,
                  drawer: routes.drawers.users.detail.pathTo(user.sid),
                }}
              >
                <UserProfilePicture user={user} />
                <Body weight="medium" color={{ light: 'gray-700', dark: 'gray-200' }}>
                  {formatFullName(user) ?? user.email}
                </Body>
                <NavIcon>
                  <ListItemNavigationArrow />
                </NavIcon>
              </ListItem>
            )}
            {isDefined(device) && (
              <ListItem
                as={SmartLink}
                to={{
                  main: routes.network.devices.list.path,
                  drawer: routes.drawers.devices.detail.pathTo(device.name),
                }}
              >
                <DeviceIcon size={DeviceIconSize.Small} />
                <Badge ends="pill" size="small" variant={onlineOfflineBadgeVariant(device.status)}>
                  {device.physical_location}
                </Badge>
                <NavIcon>
                  <ListItemNavigationArrow />
                </NavIcon>
              </ListItem>
            )}
          </List>
        )}
        <ClientSummaryWidget client={client} />
        <ConnectionDetailsWidget client={client} />
        <WirelessConnectionHistoryWidget client={client} />
      </DrawerContent>
    </Drawer>
  );
};
