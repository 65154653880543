import { Body2, Logo, space, Subheading2, VStack } from '@meterup/metric';
import { partition } from 'lodash';
import React from 'react';
import { useQueries, useQuery } from 'react-query';

import { fetchCompanyLocations, fetchOnboarding } from '../../api/api';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { routes } from '../../routes';
import { fontWeights } from '../../stitches';
import { formatAddress } from '../../utils/formatAddress';
import { isDefined } from '../../utils/isDefined';
import { LinkSlot } from '../LinkSlot';
import { Footer } from './Footer';
import { Container, FooterContent, Header, Main, MainContent } from './layout';
import { NavigatingAnchorButton } from './NavigatingButton';

export const LocationList = () => {
  const company = useCurrentCompany();

  const locations =
    useQuery(['locations', company], () => fetchCompanyLocations(company)).data ?? [];

  const onboardings = useQueries(
    locations.map((location) => ({
      queryKey: ['onboarding', company, location.sublocation_sid],
      queryFn: async () => ({
        onboarding: await fetchOnboarding(company, location.sublocation_sid),
        location,
      }),
    })),
  )
    .map((d) => d.data)
    .filter(isDefined)
    .filter((d) => isDefined(d.onboarding));

  const [completed, incomplete] = partition(
    onboardings,
    (o) => o.onboarding?.status === 'information_complete',
  );

  return (
    <Container layout="main-only">
      <Header>
        <Logo />
      </Header>
      <Main>
        <MainContent>
          <Subheading2>Configure Meter Network</Subheading2>
          <Body2>Choose a location to get started:</Body2>
          <VStack spacing={space(20)}>
            <VStack spacing={space(8)}>
              {incomplete.length > 0
                ? incomplete.map(({ onboarding, location }) => (
                    <LinkSlot
                      key={location.sublocation_sid}
                      to={routes.setup.location.root.pathTo(location.sublocation_sid)}
                    >
                      <NavigatingAnchorButton
                        label={
                          isDefined(location.address)
                            ? formatAddress(location.address)
                            : 'No address'
                        }
                        icon={
                          onboarding?.status === 'information_complete'
                            ? 'checkmarkCircle'
                            : 'attention'
                        }
                      />
                    </LinkSlot>
                  ))
                : null}
            </VStack>
            <VStack spacing={space(8)}>
              {completed.length > 0 ? (
                <>
                  <Body2 css={{ fontWeight: fontWeights.medium }}>Completed</Body2>
                  {completed.map(({ onboarding, location }) => (
                    <LinkSlot
                      key={location.sublocation_sid}
                      to={routes.setup.location.root.pathTo(location.sublocation_sid)}
                    >
                      <NavigatingAnchorButton
                        label={
                          isDefined(location.address)
                            ? formatAddress(location.address)
                            : 'No address'
                        }
                        icon={
                          onboarding?.status === 'information_complete'
                            ? 'checkmarkCircle'
                            : 'attention'
                        }
                      />
                    </LinkSlot>
                  ))}
                </>
              ) : null}
            </VStack>
          </VStack>
        </MainContent>
      </Main>
      <FooterContent>
        <Footer />
      </FooterContent>
    </Container>
  );
};
