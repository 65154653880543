import { IconName } from '@meterup/metric';

import { FeatureFlaggable } from '../../feature_flags';
import { routes } from '../../routes';

export type SidebarEntry = {
  label: string;
  path: string;
  icon: IconName;
  exact?: boolean;
} & FeatureFlaggable;

export type SidebarEntryGroup = {
  label?: string;
  entries: SidebarEntry[];
};

export type SidebarData = SidebarEntryGroup[];

export const networkSidebarEntries: SidebarData = [
  {
    entries: [
      {
        label: 'Overview',
        icon: 'home',
        path: routes.network.overview.path,
        exact: true,
      },
    ],
  },
  {
    label: 'Hardware',
    entries: [
      {
        label: 'Clients',
        icon: 'client',
        path: routes.network.clients.list.path,
      },
      {
        label: 'Devices',
        icon: 'device',
        path: routes.network.devices.list.path,
      },
    ],
  },
  {
    label: 'Settings',
    entries: [
      {
        label: 'Internet & wireless',
        icon: 'wifi',
        path: routes.network.internetWiFi.path,
      },
      {
        featureFlag: 'meter-auth-ui',
        label: 'Meter Auth',
        icon: 'client',
        path: routes.network.meterAuth.list.path,
      },
    ],
  },
];

export const settingsSidebarEntries: SidebarData = [
  {
    label: 'Organization',
    entries: [
      {
        label: 'Users',
        // TODO: Add user icon to @meterup/metric
        icon: 'client',
        path: routes.settings.users.list.path,
        exact: true,
      },
    ],
  },
];
