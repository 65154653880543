import {
  MobileSidebar,
  MobileSidebarNavItem,
  MobileSidebarToggleContainer,
  SidebarGroup,
} from '@meterup/metric';
import React from 'react';

import { MobileSidebarModal } from './MobileSidebarModal';
import { ScopedMobileSidebarEntries, ScopedMobileSidebarToggle } from './ScopedMobileSidebar';
import { settingsSidebarEntries } from './sidebarEntries';

interface SettingsSidebarMobileProps {
  onClose: () => void;
}

const SettingsSidebarMobile: React.FC<SettingsSidebarMobileProps> = ({ onClose }) => (
  <MobileSidebarModal onClose={onClose}>
    <MobileSidebar>
      <MobileSidebarToggleContainer>
        <ScopedMobileSidebarToggle entries={settingsSidebarEntries} onClick={onClose} isOpen />
      </MobileSidebarToggleContainer>

      <ScopedMobileSidebarEntries entries={settingsSidebarEntries} onClose={onClose} />

      <SidebarGroup>
        <MobileSidebarNavItem as="button" label="Log out" />
      </SidebarGroup>
    </MobileSidebar>
  </MobileSidebarModal>
);

export default SettingsSidebarMobile;
