import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { routes } from '../../routes';
import { LocationDetail } from './LocationDetail';
import { LocationList } from './LocationList';
import { OnboardingFormContainer } from './OnboardingForm/OnboardingFormContainer';

export const NetworkSetup = () => (
  <Router>
    <Switch>
      <Route path={routes.setup.root.path} component={LocationList} exact />
      <Route path={routes.setup.location.root.path} component={LocationDetail} exact />
      <Route path={routes.setup.location.form.root.path} component={OnboardingFormContainer} />
    </Switch>
  </Router>
);
