import { useLayoutEffect, useRef, useState } from 'react';
import mergeRefs from 'react-merge-refs';
import useMeasure from 'react-use-measure';

/**
 * Checks whether an element has room to scroll on the horizontal axis
 */
export const useCanScrollX = (): [(element: HTMLElement | null) => void, boolean] => {
  const [measureRef, { width }] = useMeasure();
  const scrollWidthRef = useRef<HTMLElement>(null);
  const [canScroll, setCanScroll] = useState(false);

  useLayoutEffect(() => {
    if (scrollWidthRef.current) {
      setCanScroll(scrollWidthRef.current.scrollWidth !== width);
    }
  }, [width]);

  const ref = mergeRefs([measureRef, scrollWidthRef]);

  return [ref, canScroll];
};
