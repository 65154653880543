import { FormikErrors } from 'formik';
import * as z from 'zod';

import { OnboardingFormData } from './types';

type FormikValidator<Values> = (values: Values) => void | object | Promise<FormikErrors<Values>>;

export const validate: FormikValidator<OnboardingFormData> = (values) => {
  const errors: FormikErrors<OnboardingFormData> = {};

  if (values.mainNetworkData.ssid === values.guestNetworkData.ssid) {
    errors.guestNetworkData = {};
    errors.guestNetworkData.ssid = 'Your guest SSID cannot be the same as your corporate SSID';
  }

  return errors;
};

const validWifiPassword = z
  .string()
  .min(8, 'Must contain at least 8 characters')
  .max(63, 'Must contain fewer than 63 characters')
  .regex(/^[^\s]/, 'Must not start with whitespace')
  .regex(/[^\s]$/, 'Must not end with whitespace')
  .regex(/^[ -~]*$/, 'Can only contain printable ASCII characters');

const validSSID = z
  .string()
  .min(3, 'Must contain at least 3 characters')
  .max(32, 'Must contain fewer than 32 characters')
  .regex(/^[^\s]/, 'Must not start with whitespace')
  .regex(/[^\s]$/, 'Must not end with whitespace')
  .regex(/^[ -~]*$/, 'Can only contain printable ASCII characters');

export const validOnboardingFormData = z.object({
  mainNetworkData: z.object({
    ssid: validSSID,
    password: validWifiPassword,
  }),
  guestNetworkData: z.object({
    ssid: validSSID,
    password: validWifiPassword,
  }),
});
