/* eslint-disable react/prop-types */
import { Alert, Body2, Caption2, Icon, space, TextInput } from '@meterup/metric';
import React from 'react';
import {
  Column,
  useGlobalFilter,
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersState,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import { useCanScrollX } from '../../hooks/useCanScrollX';
import { colors, css, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches';
import { isDefined } from '../../utils/isDefined';
import { SmartLink, SmartLinkNavigationProps } from '../SmartLink/SmartLink';
import Pagination from './Pagination';

const Container = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
});

const TableBar = css({
  display: 'flex',
  width: '100%',
  background: colors['gray-50'],
});

const TableTopBar = styled('div', TableBar, {
  padding: '$8',
  borderRadiusTop: '$8',
});

const TableTopBarLeft = styled('div', {});
const TableTopBarRight = styled('div', { marginLeft: 'auto' });

const TableBottomBar = styled('div', TableBar, {
  width: '100%',
  background: colors['gray-50'],
  paddingY: '$8',
  paddingX: '$16',
  borderRadiusBottom: '$8',
});

const TableContainer = styled('div', {
  overflow: 'auto',
  WebkitOverflowScrolling: 'auto',
});

const TableBase = styled('div', {
  width: '100%',
  position: 'relative',
  display: 'table',
  borderCollapse: 'collapse',
});

const TableHead = styled('div', {
  display: 'table-header-group',
});

const TableBody = styled('div', {
  display: 'table-row-group',
  borderTop: '$space$2 solid $white',
});

const TableCell = css({
  display: 'table-cell',
  paddingX: '$8',
  paddingY: '$10',
  whiteSpace: 'nowrap',
  position: 'relative',
  zIndex: 0,
  overflow: 'hidden',
  verticalAlign: 'middle',
  '&:before': {
    $$insetRight: 0,
    $$insetLeft: 0,
    content: '',
    position: 'absolute',
    inset: '8px $$insetRight 8px $$insetLeft',
  },
  '&:first-child': {
    paddingLeft: '$16',
    paddingRight: '$16',
    position: 'sticky',
    zIndex: 1,
    backgroundColor: 'transparent',
    left: 0,
    '&:before': {
      $$insetLeft: '8px',
      borderRadiusLeft: '$8',
    },
    '&:after': {
      content: '',
      position: 'absolute',
      inset: '0 8px 0 0',
      zIndex: -1,
      backgroundColor: '$$rowBackground',
      pointerEvents: 'none',
      transition: 'box-shadow 150ms ease-out',
    },
  },
  '&:last-child': {
    paddingRight: '$16',
    '&:before': {
      $$insetRight: '8px',
      borderRadiusRight: '$8',
    },
  },
  variants: {
    canScroll: {
      true: {
        '&:first-child:after': {
          boxShadow: shadows.paneRightLight,
        },
      },
    },
  },
});

const TableRow = styled('div', {
  $$rowBackground: colors['gray-50'],
  background: '$$rowBackground',
  position: 'relative',
  display: 'table-row',
  outline: 'none',
  '&:not(:last-child)': {
    borderBottom: '$space$2 solid $white',
  },
  [`& .${TableCell}:before`]: {
    transition: 'box-shadow 150ms ease-out',
    boxShadow: shadows.none,
  },
  '&:focus': {
    [`& .${TableCell}:before`]: {
      // NOTE: Shadow color values hardcoded to fix issue with overlapping transparenc
      boxShadow: shadows.focusRingLight,
    },
  },
});

const TableHeadCell = styled('div', TableCell, Caption2);

const TableHeadCellContent = styled('span', {
  display: 'inline-flex',
  alignItems: 'center',
  gap: '$4',
  color: colors['gray-500'],
  fontWeight: fontWeights.medium,
  [darkThemeSelector]: {
    color: colors['gray-200'],
  },
});

const TableHeadCellSortIcon = styled(Icon, {
  visibility: 'hidden',
  variants: {
    isVisible: {
      true: {
        visibility: 'visible',
      },
    },
  },
});

const TableDataCell = styled('div', TableCell, Body2);

const NavigableRowArrowCell: React.FC = () => (
  <TableDataCell style={{ width: 0 }} aria-hidden>
    <Icon icon="chevronRight" size={space(10)} color={{ light: 'gray-400', dark: 'gray-500' }} />
  </TableDataCell>
);

const NonNavigableRowCell: React.FC = () => <TableDataCell style={{ width: 0 }} aria-hidden />;

const GlobalSearch: React.FC<{
  instanceProps: UseGlobalFiltersInstanceProps<any> & { state: UseGlobalFiltersState<any> };
}> = ({ instanceProps }) => (
  <TextInput
    type="search"
    id="search"
    placeholder="Search"
    icon="searchScoped"
    value={instanceProps.state.globalFilter}
    onChange={(value) => {
      instanceProps.setGlobalFilter(value);
    }}
  />
);

export interface TableProps<D extends object = {}> {
  columns: ReadonlyArray<Column<D>>;
  data: readonly D[];
  linkProps?: (row: D) => SmartLinkNavigationProps | null;
  tabs?: React.ReactNode;
  emptyStateHeading?: string;
  emptyStateCopy?: string;
}

export const Table = <D extends object>({
  columns,
  data,
  linkProps,
  tabs,
  emptyStateHeading = 'No rows',
  emptyStateCopy,
}: TableProps<D>) => {
  const instance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = instance;

  const [ref, canScroll] = useCanScrollX();

  const isNavigableTable = isDefined(linkProps);

  return (
    <Container>
      <TableTopBar>
        {tabs && <TableTopBarLeft>{tabs}</TableTopBarLeft>}
        <TableTopBarRight>
          <GlobalSearch instanceProps={instance} />
        </TableTopBarRight>
      </TableTopBar>
      <TableContainer>
        <TableBase {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableHeadCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    canScroll={canScroll}
                  >
                    <TableHeadCellContent>
                      {column.render('Header')}
                      <TableHeadCellSortIcon
                        isVisible={column.isSorted}
                        icon={column.isSortedDesc ? 'chevronDown' : 'chevronUp'}
                        size={space(10)}
                      />
                    </TableHeadCellContent>
                  </TableHeadCell>
                ))}
                {isNavigableTable && <TableHeadCell aria-hidden />}
              </TableRow>
            ))}
          </TableHead>
          <TableBody ref={ref} {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const props = linkProps?.(row.original);
              const RowComponent = isDefined(props) ? SmartLink : 'div';
              const isNavigableRow = isDefined(props) && isNavigableTable;
              return (
                <TableRow {...row.getRowProps()} {...props} as={RowComponent}>
                  {row.cells.map((cell) => (
                    <TableDataCell {...cell.getCellProps()} canScroll={canScroll}>
                      {cell.render('Cell')}
                    </TableDataCell>
                  ))}
                  {isNavigableRow ? <NavigableRowArrowCell /> : <NonNavigableRowCell />}
                </TableRow>
              );
            })}
          </TableBody>
        </TableBase>
      </TableContainer>
      {page.length === 0 && (
        <Alert heading={emptyStateHeading} copy={emptyStateCopy} cornerStyle="square" />
      )}
      <TableBottomBar>
        <Pagination instanceProps={instance} />
      </TableBottomBar>
    </Container>
  );
};
