import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

const muiTheme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'system-ui',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Ubuntu',
      '"Helvetica Neue"',
      'Arial',
    ],
    sans: [
      '-apple-system',
      'system-ui',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Ubuntu',
      '"Helvetica Neue"',
      'Arial',
    ],
    mono: [
      '"SF Mono"',
      'SFMono-Regular',
      'ui-monospace',
      '"DejaVu Sans Mono"',
      'Menlo',
      'Consolas',
      'monospace',
    ],
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '0.9em',
        backgroundColor: '#272C34',
        fontWeight: 200,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          background: 'white',
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.2)',
      },
    },
    MuiButton: {
      text: {
        margin: 0,
        padding: 0,
      },
      root: {
        minWidth: 'unset',
        margin: 0,
        padding: 0,
        '&:hover': {
          backgroundColor: 'unset',
        },
      },
    },
  },
  colors: {
    dark: '#001729',
    gray: '#D7DEE4',
    darkGray: '#97A9BB',
    lightGray: '#F6F9FC',
    green: '#04c4b3',
    lightGreen: '#EAFFFD',
    purple: '#5460C8',
    lightPurple: '#ECEDFF',
    livePurple: '#666cff',
    darkBlue: '#66687A',
    online: '#44d8cc',
    offline: '#ff66b9',
    draft: '#ffe865',
    shadowDarkBlue: '#525f7f26',
  },
  backgroundColor: {
    active: '#ECEDFF',
  },
  border: '1px solid #ECEDFF',
  customShadows: '0 2px 7px 0 rgb(82 95 127 / 20%)',
  shadowsHover: '0 14px 22px 0 rgb(82 95 127 / 25%)',
});

export default muiTheme;
