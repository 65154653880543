import { api } from '@meterup/proto';

import { OnboardingData } from '../../../api/types';
import { FrequencyInterval, OnboardingFormData } from './types';

const getRotateEveryHours = (rotation: {
  rotateEveryValue: number;
  rotateEveryUnit: FrequencyInterval;
  // eslint-disable-next-line consistent-return
}): number => {
  // eslint-disable-next-line default-case
  switch (rotation.rotateEveryUnit) {
    case 'hour':
      return rotation.rotateEveryValue;
    case 'day':
      return rotation.rotateEveryValue * 24;
    case 'week':
      return rotation.rotateEveryValue * 24 * 7;
  }
};

const getRotateEveryInterval = (
  rotationHours: number,
): {
  autoRotatePassword: boolean;
  rotateEveryValue: number;
  rotateEveryUnit: FrequencyInterval;
} => ({
  autoRotatePassword: rotationHours > 0,
  // TRICKY: Bump the value to 1 if it's 0 so the form has an initial value of 1
  // if toggled on
  rotateEveryValue: rotationHours === 0 ? 1 : rotationHours,
  rotateEveryUnit: 'hour',
});
const contactTypeAPIToForm = (contactType: api.BuildingContactType): api.BuildingContactType => {
  if (contactType === api.BuildingContactType.unknown_building_contact_type) {
    return api.BuildingContactType.property_manager;
  }

  return contactType;
};

export const apiDataToFormData = (data: OnboardingData): OnboardingFormData => ({
  serviceProviderData: data.provider_sid
    ? { type: 'builtin', providerSid: data.provider_sid }
    : { type: 'no-isp' },
  mainNetworkData: {
    ssid: data.network_ssid,
    password: data.network_password,
  },
  guestNetworkData: {
    ssid: data.guest_network_ssid,
    password: data.guest_network_password,
    ...getRotateEveryInterval(data.guest_network_rotate_every_hours),
  },
  buildingData: {
    contactType: contactTypeAPIToForm(data.building_contact_type),
    tel: data.building_contact_phone,
    email: data.building_contact_email,
  },
  billingData: {
    email: data.billing_contact_email,
  },
});

export const formDataToAPIData = (
  base: OnboardingData,
  formData: OnboardingFormData,
): Partial<OnboardingData> => ({
  sublocation_sid: base.sublocation_sid,
  company_slug: base.company_slug,
  provider_sid:
    formData.serviceProviderData.type === 'builtin'
      ? formData.serviceProviderData.providerSid
      : undefined,
  network_ssid: formData.mainNetworkData.ssid,
  network_password: formData.mainNetworkData.password,
  guest_network_ssid: formData.guestNetworkData.ssid,
  guest_network_password: formData.guestNetworkData.password,
  guest_network_rotate_every_hours: formData.guestNetworkData.autoRotatePassword
    ? getRotateEveryHours(formData.guestNetworkData)
    : 0,
  building_contact_type: formData.buildingData.contactType,
  building_contact_email: formData.buildingData.email,
  building_contact_phone: formData.buildingData.tel,
});
