import { ListItemLabel, ListItemPair, ListItemValue } from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchNetworkInfo } from '../../../api/api';
import { useCurrentController } from '../../../providers/CurrentControllerProvider';
import { getWiFiQRCodeConnectionString } from '../../../utils/getWiFiQRCodeConnectionString';
import { isDefined, isDefinedAndNonEmpty } from '../../../utils/isDefined';
import { RotationStrategyBadge } from '../../badges';
import { CollapsibleList } from '../../CollapsibleList';
import { DrawerContentStack } from './DrawerContentStack';
import { PasswordViewerListItem } from './PasswordViewerListItem';
import { QRCodeListItem } from './QRCodeListItem';

export const NonMeterAuthNetworkInfo = () => {
  const controller = useCurrentController();

  const { data: networkInfo } = useQuery(
    ['network_info', controller],
    () => fetchNetworkInfo(controller),
    {
      suspense: true,
      retry: false,
    },
  );

  return isDefined(networkInfo) ? (
    <DrawerContentStack>
      <CollapsibleList title="Private network" subtitle={networkInfo.private_ssid}>
        <QRCodeListItem
          text={getWiFiQRCodeConnectionString(
            networkInfo.private_ssid,
            networkInfo.private_password,
          )}
        />
        <ListItemPair>
          <ListItemLabel>SSID</ListItemLabel>
          <ListItemValue>{networkInfo.private_ssid}</ListItemValue>
        </ListItemPair>
        <ListItemPair>
          <ListItemLabel>SSID (2.4 GHz)</ListItemLabel>
          <ListItemValue>{networkInfo.private_2g_ssid}</ListItemValue>
        </ListItemPair>
        <PasswordViewerListItem label="Password" password={networkInfo.private_password} />
      </CollapsibleList>

      {isDefinedAndNonEmpty(networkInfo.guest_ssid) && (
        <CollapsibleList title="Guest network" subtitle={networkInfo.guest_ssid} defaultOpen>
          {isDefinedAndNonEmpty(networkInfo.guest_password) && (
            <QRCodeListItem
              text={getWiFiQRCodeConnectionString(
                networkInfo.guest_ssid,
                networkInfo.guest_password,
              )}
            />
          )}
          <ListItemPair>
            <ListItemLabel>SSID</ListItemLabel>
            <ListItemValue>{networkInfo.guest_ssid}</ListItemValue>
          </ListItemPair>
          {isDefinedAndNonEmpty(networkInfo.guest_password) && (
            <PasswordViewerListItem label="Password" password={networkInfo.guest_password} />
          )}
          {isDefinedAndNonEmpty(networkInfo.guest_strategy) && (
            <ListItemPair>
              <ListItemLabel>Password rotation</ListItemLabel>
              <ListItemValue>
                <RotationStrategyBadge value={networkInfo.guest_strategy} />
              </ListItemValue>
            </ListItemPair>
          )}
        </CollapsibleList>
      )}
    </DrawerContentStack>
  ) : null;
};
