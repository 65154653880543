import { FormikProps } from 'formik';
import { get } from 'lodash';

import { Paths } from '../types/paths';
import { isDefined } from './isDefined';

export const getFieldProps = <T extends object>(
  form: FormikProps<T>,
  key: (keyof T & string) | Paths<T>,
) => ({
  ...form.getFieldProps(key),
  onChange: (eventOrValue: React.ChangeEvent | any) => {
    if (typeof eventOrValue === 'object') {
      form.handleChange(eventOrValue);
    } else {
      form.setFieldValue(key, eventOrValue);
    }
  },
  onValueChange: (value: string) => form.setFieldValue(key, value),
});

export const getErrorProps = <T extends object>(form: FormikProps<T>, key: keyof T | Paths<T>) => ({
  errorMessage: get(form.touched, key) ? get(form.errors, key) : undefined,
  hasError: get(form.touched, key) && isDefined(get(form.errors, key)),
});

export const getFieldAndErrorProps = <T extends object>(
  form: FormikProps<T>,
  key: (keyof T & string) | Paths<T>,
) => ({
  ...getFieldProps(form, key),
  ...getErrorProps(form, key),
});
