import React from 'react';
import PropTypes from 'prop-types';
import { Body } from '@meterup/metric';

const Empty = ({ message }) => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      height: '400px',
      justifyContent: 'center',
    }}
  >
    <Body color={{ light: 'gray-600', dark: 'gray-200' }}>{message}</Body>
  </div>
);

Empty.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Empty;
