import { isDefined } from './isDefined';

export function expectDefinedOrThrow<T>(
  value: T | null | undefined,
  error: Error = new Error('Expected value to be defined'),
): asserts value is T {
  if (!isDefined(value)) {
    throw error;
  }
}
