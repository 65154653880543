import QRCode from 'qrcode/lib/browser';
import nacl from 'tweetnacl';

export const generateKeyPair = () => {
  const { publicKey, secretKey } = nacl.box.keyPair();
  return {
    publicKey: btoa(String.fromCharCode.apply(null, publicKey)),
    privateKey: btoa(String.fromCharCode.apply(null, secretKey)),
  };
};

export const createWGConf = (ip, endpoint, publicKey, privateKey) => `[Interface]
PrivateKey = ${privateKey || 'INSERT PRIVATE KEY HERE'}
Address = ${ip}/32
DNS = 10.106.0.2

[Peer]
PublicKey = ${publicKey}
AllowedIPs = 10.200.0.1/8, 224.0.0.0/4
Endpoint = ${endpoint}:51820
PersistentKeepalive = 30
`;

export const createQRCode = async (data) =>
  QRCode.toDataURL(data, {
    type: 'svg',
    margin: 2,
    color: {
      dark: '#5461c8',
      light: '#fff',
    },
  });
