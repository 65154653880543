import { first } from 'lodash';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';

import { fetchControllers } from '../api/api';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { isDefined } from '../utils/isDefined';
import { useCurrentCompany } from './CurrentCompanyProvider';

interface CurrentControllerData {
  currentControllerSlug: string;
  changeCurrentControllerSlug: (slug: string) => void;
}

const CurrentControllerContext = createContext<CurrentControllerData>({} as any);

/**
 * CurrentControllerProvider provides the user's currently selected controller.
 *
 * It checks that the user's currently selected controller belongs to the user's
 * current company. Otherwise, it changes the current controller to the first
 * controller that is associated with the company.
 *
 * NOTE: If the current company does not have any controllers, this component
 * does not render its children. Since many components assume the current
 * controller is always available, this behavior is meant to make it more
 * visible if it is structurally possible for those components to receive a null
 * value for a controller.
 */
export const CurrentControllerProvider: React.FC = ({ children }) => {
  const currentCompany = useCurrentCompany();

  const controllers = useQuery(
    ['controllers', currentCompany],
    () => (currentCompany != null ? fetchControllers(currentCompany) : []),
    { enabled: currentCompany != null },
  );

  const [currentControllerSlug, setCurrentControllerSlug] = useLocalStorage<string | null>(
    'currentController',
    null,
  );

  useEffect(() => {
    if (controllers.data) {
      if (currentControllerSlug == null || !controllers.data.includes(currentControllerSlug)) {
        const newControllerSlug = first(controllers.data);

        setCurrentControllerSlug(newControllerSlug ?? null);
      }
    }
  }, [currentControllerSlug, setCurrentControllerSlug, controllers]);

  const value = useMemo(
    () =>
      isDefined(currentControllerSlug)
        ? { currentControllerSlug, changeCurrentControllerSlug: setCurrentControllerSlug }
        : null,
    [currentControllerSlug, setCurrentControllerSlug],
  );

  return value ? (
    <CurrentControllerContext.Provider value={value}>{children}</CurrentControllerContext.Provider>
  ) : null;
};

export const useCurrentController = () =>
  useContext(CurrentControllerContext).currentControllerSlug;

export const useChangeCurrentControllerCallback = () =>
  useContext(CurrentControllerContext).changeCurrentControllerSlug;
