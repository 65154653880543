import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import vpnStyles from './styles';
import { deleteVPNClient, fetchVPNClients } from '../../api/api';
import { VPNContext, storeVPNClients } from '../../providers/VPNContextProvider';
import Empty from '../Empty/Empty';
import arrowGrey from '../../assets/images/icons/arrowGrey.svg';
import arrowBlue from '../../assets/images/icons/arrowBlue.svg';
import formatTime from '../../utils/formatTime';
import { useCurrentController } from '../../providers/CurrentControllerProvider';

const VPNClient = () => {
  const classes = vpnStyles();
  const [state, dispatch] = useContext(VPNContext);
  const controller = useCurrentController();
  const history = useHistory();
  const { vpnClientSid } = useParams();
  const [img, setImg] = useState(arrowBlue);
  const [loading, setLoading] = useState(false);

  const goBack = () => {
    history.push('/vpn');
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // no get endpoint so have to fetch all
      dispatch(storeVPNClients(await fetchVPNClients(controller)));
      setLoading(false);
    };

    if (!state.vpnClients || state.vpnClients.length < 1) {
      fetchData();
    }
  }, [state.vpnClients, controller, dispatch]);

  if (loading) {
    return <Empty message="Loading..." />;
  }

  const vpnClient = state.vpnClients?.find((client) => client.sid === vpnClientSid);

  if (!vpnClient) {
    return <Empty message="VPN device not found" />;
  }

  const handleDelete = async () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm(`Are you sure you want to delete ${vpnClient.name}?`)) {
      return;
    }
    await deleteVPNClient(vpnClientSid);
    goBack();
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.titleContainer}>
        <h2>{vpnClient.name}</h2>
        <Link
          onClick={goBack}
          onMouseEnter={() => {
            setImg(arrowGrey);
          }}
          onMouseLeave={() => {
            setImg(arrowBlue);
          }}
          to="/vpn"
          className={classes.goBackLink}
        >
          <img className={classes.link} src={img} alt="Go To" />
          <p>Back to VPN</p>
        </Link>
      </Box>
      <Box className={classes.vpnClientDetails}>
        <ul>
          <li>Public Key: {vpnClient.public_key}</li>
          <li>IP Address: {vpnClient.ip_address}</li>
          <li>Created: {formatTime(new Date(vpnClient.created_at))}</li>
        </ul>
      </Box>
      <Button className={classes.createButton} onClick={handleDelete}>
        Delete
      </Button>
    </Box>
  );
};

export default VPNClient;
