import { Body2, FocusRingSelf, Icon, IconName, space } from '@meterup/metric';
import { mergeProps } from '@meterup/react-aria';
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';

import { colors, fontWeights, shadows, styled } from '../../stitches';
import { SmartLink, SmartLinkProps } from '../SmartLink/SmartLink';

const NavigatingButtonBase = styled('a', FocusRingSelf, {
  display: 'flex',
  gap: '$8',
  width: '100%',
  borderRadius: '$8',
  background: colors.white,
  boxShadow: shadows.controlTertiaryInitialLight,
  alignItems: 'center',
  padding: '$8 $12',
});

const NavigatingButtonIcon = styled(Icon, {
  color: colors['gray-600'],
});

const NavigatingButtonCopy = styled('div', {
  vStack: 0,
  alignItems: 'flex-start',
});

const NavigatingButtonLabel = styled('div', Body2, {
  fontWeight: fontWeights.medium,
  color: colors['gray-700'],
});

const NavigatingButtonDescription = styled('div', Body2, {});

const NavigatingButtonChevron = styled('div', {
  display: 'flex',
  alignItems: 'center',
  color: colors['gray-600'],
  marginLeft: 'auto',
});

export interface NavigatingButtonProps {
  label: React.ReactNode;
  description?: React.ReactNode;
  icon?: IconName;
}

const NavigatingButtonInternal = ({ icon, label, description }: NavigatingButtonProps) => (
  <>
    {icon && <NavigatingButtonIcon icon={icon} />}
    <NavigatingButtonCopy>
      <NavigatingButtonLabel>{label}</NavigatingButtonLabel>
      {description && <NavigatingButtonDescription>{description}</NavigatingButtonDescription>}
    </NavigatingButtonCopy>
    <NavigatingButtonChevron>
      <Icon icon="chevronRight" size={space(12)} />
    </NavigatingButtonChevron>
  </>
);

export const NavigatingAnchorButton = React.forwardRef<
  HTMLElement,
  NavigatingButtonProps & AnchorHTMLAttributes<HTMLAnchorElement>
>(
  // eslint-disable-next-line react/prop-types
  ({ label, icon, description, ...props }, ref) => (
    <NavigatingButtonBase {...mergeProps(props, { ref: ref as any })}>
      <NavigatingButtonInternal icon={icon} label={label} description={description} />
    </NavigatingButtonBase>
  ),
);

export const NavigatingSmartLinkButton = React.forwardRef<
  HTMLElement,
  NavigatingButtonProps & SmartLinkProps
>(
  // eslint-disable-next-line react/prop-types
  ({ label, icon, description, ...props }, ref) => (
    <NavigatingButtonBase as={SmartLink} {...mergeProps(props, { ref: ref as any })}>
      <NavigatingButtonInternal icon={icon} label={label} description={description} />
    </NavigatingButtonBase>
  ),
);

export const NavigatingButton = React.forwardRef<
  HTMLElement,
  NavigatingButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>(
  // eslint-disable-next-line react/prop-types
  ({ label, icon, description, ...props }, ref) => (
    <NavigatingButtonBase as="button" {...mergeProps(props, { ref: ref as any })}>
      <NavigatingButtonInternal icon={icon} label={label} description={description} />
    </NavigatingButtonBase>
  ),
);
