import { FocusRingSelf, Icon, space } from '@meterup/metric';
import React, { ButtonHTMLAttributes } from 'react';

import { shadows, styled } from '../../stitches';

const CloseButtonContainer = styled('button', FocusRingSelf, {
  borderRadius: 100,
  height: '$20',
  width: '$20',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: shadows.fenceAllLight,
});

export interface CloseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  'aria-label': string;
}

export const CloseButton = (props: CloseButtonProps) => (
  <CloseButtonContainer {...props}>
    <Icon icon="cross" size={space(8)} />
  </CloseButtonContainer>
);
