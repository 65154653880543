import { Body2 } from '@meterup/metric';
import React from 'react';

import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { colors, fontWeights, shadows, styled } from '../../stitches';
import { CloseButton } from './CloseButton';

export const Drawer = styled('div', {
  vStack: 0,
  alignItems: 'stretch',
  height: '100%',
  width: '100%',
  boxShadow: shadows.fenceLeftLight,
});

export const DrawerTitle = styled('h2', Body2, {
  fontWeight: fontWeights.medium,
  display: 'block',
  color: colors['gray-800'],
});

export const DrawerHeader = styled('div', {
  hStack: '$8',
  height: '$56',
  paddingX: '$20',
  boxShadow: shadows.fenceBottomLight,
  flexShrink: 0,
});

export const DrawerContent = styled('div', {
  vStack: '$16',
  alignItems: 'stretch',
  padding: '$20',
  height: '100%',
  overflow: 'auto',
});

export const DrawerFooter = styled('div', DrawerHeader, {
  display: 'flex',
  marginTop: 'auto',
  boxShadow: shadows.fenceTopBottomLight,
});

export const DrawerControls = styled('div', {
  hStack: '$8',
  marginLeft: 'auto',
});

export const CloseDrawerButton = (props: { 'aria-label'?: string }) => (
  <CloseButton
    aria-label={props['aria-label'] ?? 'Close drawer'}
    onClick={useCloseDrawerCallback()}
    type="button"
  />
);
