import { Button } from '@meterup/metric';
import * as d3 from 'd3';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import mergeRefs from 'react-merge-refs';
import useMeasure from 'react-use-measure';

import { styled } from '../../stitches';

const MIN_ZOOM = 0.25;
const MAX_ZOOM = 100;

const Container = styled('div', {
  width: '100%',
  height: '100%',
  maxHeight: 400,
  overflow: 'hidden',
  position: 'relative',
  outline: 'none',
  '&:before': {
    content: '',
    position: 'absolute',
    inset: 0,
    boxShadow: '0 0 0 0 #474d81aa inset, 0 0 0 0 #8790daaa inset',
    zIndex: 1,
    pointerEvents: 'none',
    transition: 'box-shadow 75ms ease-out',
  },
  '&:focus': {
    '&:before': {
      boxShadow: '0 0 0 1px rgba(105, 119, 225, 0.9) inset, 0 0 0 3px #8790daaa inset',
    },
  },
});

const CheckerPattern = styled('svg', { position: 'absolute', inset: 0, pointerEvents: 'none' });

const PanningContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$12',
  transformOrigin: '0 0',
});

const ControlsRegion = styled('div', {
  position: 'absolute',
  top: '$8',
  left: '$8',
  gap: '$8',
  display: 'flex',
  flexDirection: 'row',
});

export const PanAndZoomRegion: React.FC = ({ children }) => {
  const [transform, setTransform] = useState(d3.zoomIdentity);
  const zoomContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [measureContainerRef, { width, height }] = useMeasure();
  const zoomBehaviorRef = useRef<d3.ZoomBehavior<HTMLDivElement, any> | null>(null);
  const zoomSelectionRef = useRef<d3.Selection<HTMLDivElement, unknown, null, undefined> | null>(
    null,
  );

  useEffect(() => {
    if (zoomContainerRef.current) {
      const zoomBehavior = d3
        .zoom<HTMLDivElement, any>()
        .scaleExtent([MIN_ZOOM, MAX_ZOOM])
        .filter(
          (e) =>
            e.touches?.length > 1 ||
            containerRef.current === document.activeElement ||
            (e.type === 'mousedown' && (containerRef.current?.contains(e.currentTarget) ?? false)),
        );

      zoomBehaviorRef.current = zoomBehavior;
      zoomSelectionRef.current = d3.select(zoomContainerRef.current);

      zoomBehavior.on('zoom', (e: d3.D3ZoomEvent<any, any>) => setTransform(e.transform));
      zoomBehavior(zoomSelectionRef.current);

      return () => {
        zoomBehavior.on('zoom', null);
      };
    }

    return () => {};
  }, []);

  const canZoomIn = transform.k < MAX_ZOOM;
  const canZoomOut = transform.k > MIN_ZOOM;
  const canReset = !isEqual(transform, d3.zoomIdentity);

  const zoomIn = () => {
    if (zoomBehaviorRef.current && zoomSelectionRef.current) {
      zoomBehaviorRef.current.scaleBy(zoomSelectionRef.current, 1.5);
    }
  };

  const zoomOut = () => {
    if (zoomBehaviorRef.current && zoomSelectionRef.current) {
      zoomBehaviorRef.current.scaleBy(zoomSelectionRef.current, 0.5);
    }
  };

  const resetZoom = () => {
    if (zoomBehaviorRef.current && zoomSelectionRef.current) {
      zoomBehaviorRef.current?.transform(zoomSelectionRef.current, d3.zoomIdentity);
    }
  };

  return (
    <Container ref={mergeRefs([measureContainerRef, containerRef])} tabIndex={-1}>
      <div ref={zoomContainerRef}>
        {/* TRICKY: We want the checker pattern to pan and zoom infinitely, so we
      use an SVG with a rect that spans the full width and height filled with a
      checker pattern, then apply the same transform to the pattern. */}
        <CheckerPattern viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
          <defs>
            <pattern
              id="checker"
              viewBox="0,0,16,16"
              width={16}
              height={16}
              patternUnits="userSpaceOnUse"
              patternTransform={`translate(${transform.x},${transform.y}) scale(${transform.k})`}
            >
              <rect x={0} y={0} width={8} height={8} />
              <rect x={8} y={8} width={8} height={8} />
            </pattern>
          </defs>
          <rect x="0" y="0" width={width} height={height} fill="url(#checker)" opacity={0.04} />
        </CheckerPattern>
        <PanningContent
          style={{
            transform: `translate(${transform.x}px,${transform.y}px) scale(${transform.k})`,
          }}
        >
          {children}
        </PanningContent>
      </div>
      <ControlsRegion>
        <Button
          variant="secondary"
          size="small"
          arrangement="hidden-label"
          icon="plus"
          onClick={zoomIn}
          disabled={!canZoomIn}
        >
          Zoom in
        </Button>
        <Button
          variant="secondary"
          size="small"
          arrangement="hidden-label"
          icon="minus"
          onClick={zoomOut}
          disabled={!canZoomOut}
        >
          Zoom out
        </Button>
        <Button variant="secondary" size="small" onClick={resetZoom} disabled={!canReset}>
          Reset
        </Button>
      </ControlsRegion>
    </Container>
  );
};
