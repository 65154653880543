import { useQuery } from 'react-query';

import { fetchControllers, fetchStatus } from '../../api/api';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { isDefined } from '../../utils/isDefined';

export const useControllerList = () => {
  const company = useCurrentCompany();

  const controllers = useQuery(['controllers_with_status', company], async () => {
    const controllerSlugs = await fetchControllers(company);
    const statuses = await Promise.all(controllerSlugs.map((slug) => fetchStatus(slug)));
    return statuses.filter(isDefined);
  });

  return controllers.data ?? [];
};
