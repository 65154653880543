import {
  Button,
  Heading,
  HStack,
  List,
  PrimaryField,
  space,
  TextInput,
  VStack,
} from '@meterup/metric';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';

import { getFieldAndErrorProps } from '../../../../utils/getFieldAndErrorProps';
import { BackButton } from '../components/BackButton';
import { NextStepButton } from '../components/NextStepButton';
import { PasswordStrengthIndicator } from '../components/PasswordStrengthIndicator';
import { WordCountListItem } from '../components/WordCountListItem';
import { useSuggestPasswordState } from '../hooks/useSuggestPasswordState';
import { OnboardingFormData } from '../types';

export const NetworkSetupStep: React.FC = () => {
  const form = useFormikContext<OnboardingFormData>();

  const onSetPassword = useCallback(
    (pwd: string) => {
      form.setFieldValue('mainNetworkData.password', pwd);
    },
    [form],
  );

  const { wordCount, setWordCount, refreshPassword } = useSuggestPasswordState(onSetPassword);

  return (
    <VStack spacing={space(16)}>
      <Heading>Network setup</Heading>
      <List>
        <PrimaryField
          InputComponent={TextInput}
          label="Corporate SSID"
          autoComplete="off"
          autoFocus
          {...getFieldAndErrorProps(form, 'mainNetworkData.ssid')}
        />
      </List>
      <List>
        <PrimaryField
          InputComponent={TextInput}
          label="Password"
          autoComplete="off"
          controls={
            <>
              <PasswordStrengthIndicator password={form.values.mainNetworkData.password ?? ''} />
              <Button
                size="small"
                icon="arrowRotate"
                type="button"
                arrangement="hidden-label"
                variant="secondary"
                onClick={refreshPassword}
              >
                Suggest a password
              </Button>
            </>
          }
          {...getFieldAndErrorProps(form, 'mainNetworkData.password')}
        />
        <WordCountListItem value={wordCount} onChange={setWordCount} />
      </List>
      <HStack justify="between">
        <BackButton />
        <NextStepButton requireValidationAtPath="mainNetworkData" />
      </HStack>
    </VStack>
  );
};
