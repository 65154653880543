import { api } from '@meterup/proto';
import React, { useState } from 'react';

import { ConfirmationView } from './ConfirmationView';
import { EmailsAndRoleForm } from './EmailsAndRoleForm';
import { InviteUsersFormData } from './InviteUsersFormData';

export const InviteUsersDrawer = () => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [formData, setFormData] = useState<InviteUsersFormData>({
    emailListRawText: '',
    role: api.CompanyMembershipRole.member,
  });

  return isConfirming ? (
    <ConfirmationView values={formData} onClickBack={() => setIsConfirming(false)} />
  ) : (
    <EmailsAndRoleForm
      initialValues={formData}
      onSubmit={(values) => {
        setFormData(values);
        setIsConfirming(true);
      }}
    />
  );
};
