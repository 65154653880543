import { ExtractRouteParams, matchPath } from 'react-router';

import { PathMatchOptions } from '../utils/locations';
import { useDrawerLocation } from './useDrawerLocation';

/**
 * If a drawer is open, attempts to match the given path to the drawer's
 * location. If the path matches, returns the parameters of the matched path.
 * This is similar to `useParams` from `react-router`.
 *
 * @example
 *  const params = useDrawerParams('/users/:id');
 *  // params === { id: '123' } or null
 */
export const useDrawerParams = <Path extends string = '', Params = ExtractRouteParams<Path>>(
  path: Path,
  { exact, sensitive, strict }: PathMatchOptions = {},
): Params | null => {
  const location = useDrawerLocation();

  if (location) {
    const match = matchPath<Params>(location.pathname, {
      path,
      exact,
      sensitive,
      strict,
    });

    if (match) {
      return match.params;
    }

    return null;
  }

  return null;
};
