import { Heading, HStack, space, VStack } from '@meterup/metric';
import React, { useContext, useEffect, useState } from 'react';

import { fetchVPNClients } from '../../api/api';
import { useCurrentController } from '../../providers/CurrentControllerProvider';
import { storeVPNClients, VPNContext } from '../../providers/VPNContextProvider';
import { routes } from '../../routes';
import { FixMeLater } from '../../types/fixMeLater';
import Loading from '../Loading/Loading';
import { Table } from '../Table/Table';
import { CellRelativeTimestamp } from '../Table/tableCells';

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Public Key',
    accessor: 'public_key',
  },
  {
    Header: 'IP',
    accessor: 'ip_address',
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    Cell: CellRelativeTimestamp,
  },
];

const VPN = () => {
  const [rows, setRows] = useState<FixMeLater[] | null>(null);
  const controller = useCurrentController();
  const [, dispatch] = useContext(VPNContext);

  useEffect(() => {
    const fetchData = async () => {
      const clients = await fetchVPNClients(controller);
      setRows(clients);
      dispatch(storeVPNClients(clients));
    };

    if (controller) {
      fetchData();
    }
  }, [controller, dispatch]);

  if (rows) {
    return (
      <VStack spacing={space(16)}>
        <HStack justify="between" spacing={space(16)}>
          <Heading>VPN</Heading>
        </HStack>
        <Table
          columns={columns}
          data={rows}
          linkProps={(client) => ({ to: routes.vpn.client.pathTo(client.sid) })}
        />
      </VStack>
    );
  }
  return <Loading />;
};

export default VPN;
