import { Alert } from '@meterup/metric';
import React from 'react';

import {
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
} from '../Drawer/Drawer';

export const NotFoundDrawer = () => (
  <Drawer>
    <DrawerHeader>
      <DrawerTitle>Not Found</DrawerTitle>
      <DrawerControls>
        <CloseDrawerButton />
      </DrawerControls>
    </DrawerHeader>
    <DrawerContent>
      <Alert copy="The requested drawer was not found." />
    </DrawerContent>
  </Drawer>
);
