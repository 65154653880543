import { Button, List, ListItemLabel, ListItemPair, ListItemValue } from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import { fetchControllerSSID } from '../../api/api';
import { NotFoundError } from '../../errors';
import { useFeatureFlag } from '../../hooks/useFeatureFlags';
import { useCurrentController } from '../../providers/CurrentControllerProvider';
import { routes } from '../../routes';
import { expectDefinedOrThrow } from '../../utils/expectDefinedOrThrow';
import { getWiFiQRCodeConnectionString } from '../../utils/getWiFiQRCodeConnectionString';
import { isDefined, isDefinedAndNonEmpty } from '../../utils/isDefined';
import { RotationStrategyBadge } from '../badges';
import { CollapsibleList } from '../CollapsibleList';
import {
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
} from '../Drawer/Drawer';
import { PasswordViewerListItem } from './AddClientDrawer/PasswordViewerListItem';
import { QRCodeListItem } from './AddClientDrawer/QRCodeListItem';

const DRAWER_TITLE = routes.drawers.ssid.detail.label;

export const SSIDDetailDrawer = () => {
  const controller = useCurrentController();
  const { ssidName } = useParams<{ ssidName: string }>();

  const ssid = useQuery(
    ['controller_ssids', controller, ssidName],
    () => fetchControllerSSID(controller, ssidName),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(ssid, new NotFoundError(`SSID not found`));

  const shouldShowSplashButton = useFeatureFlag('tablet-splash-screen');

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>{DRAWER_TITLE}</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        {isDefined(ssid.password) && (
          <CollapsibleList title="QR code" defaultOpen>
            <QRCodeListItem text={getWiFiQRCodeConnectionString(ssid.ssid, ssid.password.value)} />
          </CollapsibleList>
        )}
        <List>
          <ListItemPair>
            <ListItemLabel>SSID</ListItemLabel>
            <ListItemValue>{ssid.ssid}</ListItemValue>
          </ListItemPair>
          {isDefined(ssid.password) && (
            <PasswordViewerListItem label="Password" password={ssid.password.value} />
          )}
          {ssid.password?.type === 'rotating' &&
            isDefinedAndNonEmpty(ssid.password.rotation_interval_name) && (
              <ListItemPair>
                <ListItemLabel>Password rotation</ListItemLabel>
                <ListItemValue>
                  <RotationStrategyBadge
                    value={
                      ssid.password.rotation_interval_name === 'UNKNOWN'
                        ? 'DISABLED'
                        : ssid.password.rotation_interval_name
                    }
                  />
                </ListItemValue>
              </ListItemPair>
            )}
        </List>
        {shouldShowSplashButton && (
          <Button
            as={Link}
            to={routes.network.ssidInstructions.pathTo(ssid.ssid)}
            variant="tertiary"
            icon="phone"
            width="full"
            size="large"
            arrangement="leading-icon"
          >
            Enter tablet display
          </Button>
        )}
      </DrawerContent>
    </Drawer>
  );
};
