import { Alert, Body2, Button } from '@meterup/metric';
import { partition } from 'lodash';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { createUser, getCompanyUsers } from '../../../api/api';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNavigateMainAndDrawerCallback } from '../../../hooks/useNavigateMainAndDrawerCallback';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { routes } from '../../../routes';
import { fontWeights, styled } from '../../../stitches';
import { UserRoleBadge } from '../../badges';
import {
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '../../Drawer/Drawer';
import { RefreshOrContactSupportBoilerplate } from '../../ErrorFallback/ErrorFallback';
import {
  extractUniqueEmailAddressesFromDelimitedString,
  InviteUsersFormData,
} from './InviteUsersFormData';

const SimpleList = styled('ul', {
  listStyle: 'none',
  margin: 0,
  padding: 0,
});

const SimpleListItem = styled('li', Body2, {
  listStyleType: 'disc',
  fontWeight: fontWeights.medium,
  marginLeft: '$20',
  paddingLeft: '$4',
});

export const ConfirmationView = ({
  values,
  onClickBack,
}: {
  values: InviteUsersFormData;
  onClickBack: () => void;
}) => {
  const closeDrawer = useCloseDrawerCallback();
  const company = useCurrentCompany();

  const client = useQueryClient();
  const navigateDrawer = useNavigateMainAndDrawerCallback();

  const { data: users } = useQuery(['company_users', company], () => getCompanyUsers(company), {
    suspense: true,
  });

  const existingEmails = users?.users?.map((user) => user.email) ?? [];

  const [toInvite, existing] = partition(
    extractUniqueEmailAddressesFromDelimitedString(values.emailListRawText),
    (email) => !existingEmails.includes(email),
  );

  const createUserMutation = useMutation(
    ['create_users', toInvite, values.role],
    async () => Promise.all(toInvite.map((email) => createUser(company, email, values.role))),
    {
      onSuccess: (userList) => {
        client.invalidateQueries(['company_users']);

        if (userList.length === 1 && userList[0]) {
          const user = userList[0];
          navigateDrawer({
            drawer: routes.drawers.users.detail.pathTo(user.sid),
          });
        } else {
          closeDrawer();
        }
      },
    },
  );

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>{routes.drawers.users.invite.label}</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        {createUserMutation.error && (
          <Alert
            variant="negative"
            icon="warning"
            heading="Unexpected error"
            copy={
              <>
                There was an issue while inviting these users.{' '}
                <RefreshOrContactSupportBoilerplate />
              </>
            }
          />
        )}
        {toInvite.length > 0 && (
          <>
            <Body2>
              The following email addresses will be added to your organization with{' '}
              <UserRoleBadge role={values.role} /> permissions:
            </Body2>
            <SimpleList>
              {toInvite.map((email) => (
                <SimpleListItem key={email}>{email}</SimpleListItem>
              ))}
            </SimpleList>
          </>
        )}
        {existing.length > 0 && (
          <>
            <Body2>
              The following email addresses are already in your organization and won't be added
              again:
            </Body2>
            <SimpleList>
              {existing.map((email) => (
                <SimpleListItem key={email}>{email}</SimpleListItem>
              ))}
            </SimpleList>
          </>
        )}
      </DrawerContent>
      <DrawerFooter>
        <DrawerControls>
          <Button
            variant="secondary"
            type="button"
            disabled={createUserMutation.isLoading}
            onClick={onClickBack}
          >
            Back
          </Button>
          <Button
            loading={createUserMutation.isLoading}
            onClick={() => createUserMutation.mutate()}
          >
            {toInvite.length > 0 ? 'Invite' : 'Close'}
          </Button>
        </DrawerControls>
      </DrawerFooter>
    </Drawer>
  );
};
