import {
  BasicSelect,
  BasicSelectItem,
  Box,
  Button,
  CompositeField,
  Heading,
  HStack,
  List,
  PrimaryFieldComposite,
  SecondaryField,
  Small,
  space,
  TextInput,
  VStack,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import { useFormikContext } from 'formik';
import React from 'react';
import Input from 'react-phone-number-input/input';

import { getFieldAndErrorProps } from '../../../../utils/getFieldAndErrorProps';
import { BackButton } from '../components/BackButton';
import { OnboardingFormData } from '../types';

export const MeterInstallStep: React.FC = () => {
  const form = useFormikContext<OnboardingFormData>();
  const isContactSelf = form.values.buildingData.contactType === api.BuildingContactType.self;
  return (
    <VStack spacing={space(16)}>
      <Heading>Meter install</Heading>
      <List>
        <PrimaryFieldComposite
          label="Who do we contact to access your building?"
          fields={
            <HStack align="center" justify="between">
              <Small weight="medium">Contact type</Small>
              <Box width="fit-content">
                <CompositeField
                  label="Building contact type"
                  controlSize="small"
                  InputComponent={BasicSelect}
                  {...getFieldAndErrorProps(form, 'buildingData.contactType')}
                >
                  <BasicSelectItem value={api.BuildingContactType.property_manager}>
                    Property Manager
                  </BasicSelectItem>
                  <BasicSelectItem value={api.BuildingContactType.riser_manager}>
                    Riser Manager
                  </BasicSelectItem>
                  <BasicSelectItem value={api.BuildingContactType.self}>Self</BasicSelectItem>
                  <BasicSelectItem value={api.BuildingContactType.other}>Other</BasicSelectItem>
                </CompositeField>
              </Box>
            </HStack>
          }
        />
        <SecondaryField
          label={`${isContactSelf ? 'Your' : 'Their'} phone number`}
          InputComponent={Input as any}
          {...getFieldAndErrorProps(form, 'buildingData.tel')}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          {...({ inputComponent: TextInput } as any)}
          defaultCountry="US"
        />
        <SecondaryField
          label={`${isContactSelf ? 'Your' : 'Their'} email`}
          InputComponent={TextInput}
          {...getFieldAndErrorProps(form, 'buildingData.email')}
        />
      </List>
      <HStack justify="between">
        <BackButton />
        <Box width="full">
          <Button width="full" size="large" type="submit">
            Complete
          </Button>
        </Box>
      </HStack>
    </VStack>
  );
};
