import { Alert, Button } from '@meterup/metric';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { deleteUser } from '../../../api/api';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useDrawerParams } from '../../../hooks/useDrawerParams';
import { useNavigateMainAndDrawerCallback } from '../../../hooks/useNavigateMainAndDrawerCallback';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { routes } from '../../../routes';
import { DrawerContent, DrawerControls, DrawerFooter } from '../../Drawer/Drawer';
import { RefreshOrContactSupportBoilerplate } from '../../ErrorFallback/ErrorFallback';
import { UserDetails } from './UserDetails';

export const RemoveUserContent = () => {
  const currentCompany = useCurrentCompany();
  const userSid = useDrawerParams(routes.drawers.users.remove.path)!.userSid as string;

  const navigateFn = useNavigateMainAndDrawerCallback();
  const closeDrawer = useCloseDrawerCallback();
  const client = useQueryClient();

  const deleteUserMutation = useMutation(() => deleteUser(currentCompany, userSid), {
    onSuccess: () => {
      closeDrawer();
      client.invalidateQueries(['company_users']);
    },
  });

  return (
    <>
      <DrawerContent>
        {deleteUserMutation.error && (
          <Alert
            variant="negative"
            icon="warning"
            heading="Unexpected error"
            copy={
              <>
                There was an issue while removing this user. <RefreshOrContactSupportBoilerplate />
              </>
            }
          />
        )}
        <Alert
          icon="attention"
          heading="Confirmation required"
          copy="Removing this user will revoke any access they have to your Meter products. Do you wish to proceed?"
        />
        <UserDetails />
      </DrawerContent>
      <DrawerFooter>
        <DrawerControls>
          <Button
            variant="secondary"
            onClick={() =>
              navigateFn({
                drawer: routes.drawers.users.detail.pathTo(userSid),
              })
            }
          >
            Cancel
          </Button>
          <Button variant="destructive" onClick={() => deleteUserMutation.mutate()}>
            Remove
          </Button>
        </DrawerControls>
      </DrawerFooter>
    </>
  );
};
