import { useIsActiveMatcher } from '../../hooks/useIsActive';
import { SidebarData, SidebarEntry } from './sidebarEntries';

export const useActiveSidebarEntry = (entries: SidebarData): SidebarEntry | null => {
  const isActiveTest = useIsActiveMatcher();

  let activeItem: SidebarEntry | null = null;

  entries.forEach((group) => {
    group.entries.forEach((item) => {
      if (isActiveTest(item.path, { exact: item.exact })) {
        activeItem = item;
      }
    });
  });

  return activeItem;
};
