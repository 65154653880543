import { Alert } from '@meterup/metric';
import { ErrorBoundary } from '@sentry/react';
import React from 'react';

import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { routes } from '../../../routes';
import {
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
} from '../../Drawer/Drawer';
import { RefreshOrContactSupportBoilerplate } from '../../ErrorFallback/ErrorFallback';
import { MeterAuthTokenWizard } from './MeterAuthTokenWizard';
import { NonMeterAuthNetworkInfo } from './NonMeterAuthNetworkInfo';

const DRAWER_TITLE = routes.drawers.clients.add.label;

export const AddClientDrawer = () => {
  const flags = useFeatureFlags();
  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>{DRAWER_TITLE}</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <ErrorBoundary
          fallback={
            <Alert
              heading="Unexpected error"
              copy={
                <>
                  We were unable to retrieve your network information.{' '}
                  <RefreshOrContactSupportBoilerplate />
                </>
              }
            />
          }
        >
          {flags['meter-auth-ui'] ? <MeterAuthTokenWizard /> : <NonMeterAuthNetworkInfo />}
        </ErrorBoundary>
      </DrawerContent>
    </Drawer>
  );
};
